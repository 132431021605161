import * as _ from 'underscore'
import { addYears, addMonths, addWeeks, differenceInMonths, differenceInDays, parseISO, startOfYear,differenceInYears } from "date-fns";
import * as bootbox from './libs/bootbox/bootbox'
// import { DataManager } from './app/com/vbee/data/DataManager';
 

export const daysPerWeekly = 7;
export const daysPer4Weekly = 28;
export const periodsPerYearWeely = 52;
export const periodsPerYear4Weely = 13;

export function calculatePeriodNumber(startTime, endyear, periodKind, period) {
    // Construct the end time for the specified year and period
    let endTime;
    const startOfYearDate = startOfYear(new Date(endyear, 0, 1));
    
    switch (periodKind) {
        case "Yearly":
            endTime = addYears(startOfYearDate, period - 1);
            break;
        case "Quarterly":
            endTime = addMonths(startOfYearDate, 3 * (period - 1));
            break;
        case "4-Weekly":
            endTime = addWeeks(startOfYearDate, 4 * (period - 1));
            break;
        case "Monthly":
            endTime = new Date(endyear, period - 1, 1);
            break;
        case "Weekly":
            endTime = addWeeks(startOfYearDate, period - 1);
            break;
        default:
            throw new Error(`Invalid periodKind: ${periodKind}`);
    }

    // Calculate the period number based on the periodKind
    let periodNumber;
    switch (periodKind) {
        case "Yearly":
            periodNumber = Math.floor(differenceInMonths(endTime, startTime) / 12) + 1;
            break;
        case "Quarterly":
            periodNumber = Math.floor(differenceInMonths(endTime, startTime) / 3) + 1;
            break;
        case "4-Weekly":
            periodNumber = Math.floor(differenceInDays(endTime, startTime) / 28) + 1;
            break;
        case "Monthly":
            periodNumber = differenceInMonths(endTime, startTime) + 1;
            break;
        case "Weekly":
            periodNumber = Math.floor(differenceInDays(endTime, startTime) / 7) + 1;
            break;
    }

    return periodNumber;
}

export function replaceNamesWithIds(expression, ids){
	const idMap = ids.reduce((acc, { variableId, name }) => {
		acc[name] = variableId;
		return acc;
	}, {});

   
	let modifiedExpression = expression;
	for (const [name, variableId] of Object.entries(idMap)) {
		const regex = new RegExp(`\\b${name}\\b`, 'g'); 
		modifiedExpression = modifiedExpression.replace(regex, variableId);
	}

	return modifiedExpression;
};

export function calculateYearPeriod(startTime, periodNumber, periodKind){
    // Calculate the end time based on the periodNumber and periodKind
    let endTime;
    switch (periodKind) {
      case 'Yearly':
        endTime = addYears(startTime, periodNumber - 1);
        break;
      case 'Quarterly':
        endTime = addMonths(startTime, 3 * (periodNumber - 1));
        break;
      case '4-Weekly':
        endTime = addWeeks(startTime, 4 * (periodNumber - 1));
        break;
      case 'Monthly':
        endTime = addMonths(startTime, periodNumber - 1);
        break;
      case 'Weekly':
        endTime = addWeeks(startTime, periodNumber - 1);
        break;
      default:
        throw new Error(`Invalid periodKind: ${periodKind}`);
    }

    // Determine the year of the endTime
    const endYear = endTime.getFullYear();
    
    // Determine the period within the endYear
    let period;
    const startOfYearDate = startOfYear(new Date(endYear, 0, 1));
    switch (periodKind) {
      case 'Yearly':
        period = differenceInYears(endTime, startOfYearDate) + 1;
        break;
      case 'Quarterly':
        period = Math.floor(differenceInMonths(endTime, startOfYearDate) / 3) + 1;
        break;
      case '4-Weekly':
        period = Math.floor(differenceInDays(endTime, startOfYearDate) / 28) + 1;
        break;
      case 'Monthly':
        period = differenceInMonths(endTime, startOfYearDate) + 1;
        break;
      case 'Weekly':
        period = Math.floor(differenceInDays(endTime, startOfYearDate) / 7) + 1;
        break;
    }

    return {"year":endYear,"period":period} ;
  };
export function  isScenarioType (type){
	if(type != undefined && (type == 'beeppackage_CodeContainer' 
												|| type == 'transformation_PeriodDataset'
												|| type == 'transformation_ScenarioExecution'
												|| type == 'transformation_PlanScenario')){
		return true;
	}else{
		return false;
	} 

}
export function sayHello () {
  console.log('hello')
}

/*export function defineKoWeightNumeric (ko) {
	ko.bindingHandlers.weightNumeric = {
		init: function (element) {
			$(element).on("keydown", function (event) {
				// Allow: backspace, delete, tab, escape, and enter
				if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 ||
					// Allow: Ctrl+A
					(event.keyCode == 65 && event.ctrlKey === true) ||
					// Allow: Ctrl+v
					(event.keyCode == 86 && event.ctrlKey === true) ||
					// Allow: Ctrl+c
					(event.keyCode == 67 && event.ctrlKey === true) ||
					// Allow: home, end, left, right
					(event.keyCode >= 35 && event.keyCode <= 39)) {
					// let it happen, don't do anything
					return;
				}
				else {
					// Ensure that it is a number and stop the keypress
					//if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
					if (event.shiftKey || ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) ||
						!(parseInt(event.key) >= 0 || parseInt(event.key) <= 9)) {
						event.preventDefault();
					}
				}
			});
		}
	};
}*/
export function defineKoWeightNumeric(ko,perRoundDec) {
	ko.bindingHandlers.weightNumeric = {
		init: function (element, valueAccessor) {
			let options = valueAccessor();
        	let decimalLimit = perRoundDec ? perRoundDec : 4;//ko.unwrap(options.decimalLimit) || 2;
			$(element).on("keydown", function (event) {
				$(element).prop('oldValue', $(element).val());
				window.utils.checkNumericValue(event, element);
			});
			$(element).on("keyup", function (event) {
				let currentValue = $(event.target).val();
				if (!validate(currentValue)) {
					$(event.target).val($(element).prop('oldValue'));
				}
			});
			function validate(s) {
				// Regex to match a valid number with optional negative sign, digits, and up to 'decimalLimit' decimal places
				var rgx = new RegExp(`^\\-?[0-9]*\\.?[0-9]{0,${decimalLimit}}$`);
				return rgx.test(s);
			}
		}
	};
}

export function defineKoNumeric (ko) {
	ko.bindingHandlers.numeric = {
		init: function (element, valueAccessor) {
			$(element).on("keydown", function (event) {
				$(element).prop('oldValue', $(element).val());
				window.utils.checkNumericValue(event, element);
			});
			$(element).on("keyup", function (event) {
				if (!validate($(event.target).val())) {
					$(event.target).val($(element).prop('oldValue'));
				}
				function validate(s) {
					var rgx = /\-?[0-9]*\.?[0-9]*$/;
					if (s.match(rgx) == s) {
						return true;
					}
					return false;
				}
			});
		}
	};
}

export function htmlJuliaEscape(val) {
	let str = (typeof val == 'string') ? val : (val.get("name") + val.get("id"));
	return String(str)
		.replace(/@/g, '')
		.replace(/ /g, '')
		.replace(/#/g, '')
		.replace(/-/g, '_');
}

export function replacePackageIds(pack,replaceIds) {
	var keys = Object.getOwnPropertyNames(replaceIds);
	var packStr = JSON.stringify(pack);
	for (var k = 0; k < keys.length; k++) {
		var re = new RegExp(keys[k], 'g');
		packStr = packStr.replace(re, replaceIds[keys[k]]);
	}
	return JSON.parse(packStr);
}

export function compareVersions(version1, version2) {
	const normalizeVersion = (version) => version?version.toString().replace(/[^\d.]/g, ''):0;
	const normalizedVersion1 = normalizeVersion(version1);
	const normalizedVersion2 = normalizeVersion(version2);
	if (normalizedVersion1 >= normalizedVersion2) {
		return true;
	}
	return false;
}
export function languageList() {
		return {
		af_NA: "Afrikaans (Namibia)",
		af_ZA: "Afrikaans (South Africa)",
		af: "Afrikaans",
		ak_GH: "Akan (Ghana)",
		ak: "Akan",
		sq_AL: "Albanian (Albania)",
		sq: "Albanian",
		am_ET: "Amharic (Ethiopia)",
		am: "Amharic",
		ar_DZ: "Arabic (Algeria)",
		ar_BH: "Arabic (Bahrain)",
		ar_EG: "Arabic (Egypt)",
		ar_IQ: "Arabic (Iraq)",
		ar_JO: "Arabic (Jordan)",
		ar_KW: "Arabic (Kuwait)",
		ar_LB: "Arabic (Lebanon)",
		ar_LY: "Arabic (Libya)",
		ar_MA: "Arabic (Morocco)",
		ar_OM: "Arabic (Oman)",
		ar_QA: "Arabic (Qatar)",
		ar_SA: "Arabic (Saudi Arabia)",
		ar_SD: "Arabic (Sudan)",
		ar_SY: "Arabic (Syria)",
		ar_TN: "Arabic (Tunisia)",
		ar_AE: "Arabic (United Arab Emirates)",
		ar_YE: "Arabic (Yemen)",
		ar: "Arabic",
		hy_AM: "Armenian (Armenia)",
		hy: "Armenian",
		as_IN: "Assamese (India)",
		as: "Assamese",
		asa_TZ: "Asu (Tanzania)",
		asa: "Asu",
		az_Cyrl: "Azerbaijani (Cyrillic)",
		az_Cyrl_AZ: "Azerbaijani (Cyrillic, Azerbaijan)",
		az_Latn: "Azerbaijani (Latin)",
		az_Latn_AZ: "Azerbaijani (Latin, Azerbaijan)",
		az: "Azerbaijani",
		bm_ML: "Bambara (Mali)",
		bm: "Bambara",
		eu_ES: "Basque (Spain)",
		eu: "Basque",
		be_BY: "Belarusian (Belarus)",
		be: "Belarusian",
		bem_ZM: "Bemba (Zambia)",
		bem: "Bemba",
		bez_TZ: "Bena (Tanzania)",
		bez: "Bena",
		bn_BD: "Bengali (Bangladesh)",
		bn_IN: "Bengali (India)",
		bn: "Bengali",
		bs_BA: "Bosnian (Bosnia and Herzegovina)",
		bs: "Bosnian",
		bg_BG: "Bulgarian (Bulgaria)",
		bg: "Bulgarian",
		my_MM: "Burmese (Myanmar [Burma])",
		my: "Burmese",
		ca_ES: "Catalan (Spain)",
		ca: "Catalan",
		tzm_Latn: "Central Morocco Tamazight (Latin)",
		tzm_Latn_MA: "Central Morocco Tamazight (Latin, Morocco)",
		tzm: "Central Morocco Tamazight",
		chr_US: "Cherokee (United States)",
		chr: "Cherokee",
		cgg_UG: "Chiga (Uganda)",
		cgg: "Chiga",
		zh_Hans: "Chinese (Simplified Han)",
		zh_Hans_CN: "Chinese (Simplified Han, China)",
		zh_Hans_HK: "Chinese (Simplified Han, Hong Kong SAR China)",
		zh_Hans_MO: "Chinese (Simplified Han, Macau SAR China)",
		zh_Hans_SG: "Chinese (Simplified Han, Singapore)",
		zh_Hant: "Chinese (Traditional Han)",
		zh_Hant_HK: "Chinese (Traditional Han, Hong Kong SAR China)",
		zh_Hant_MO: "Chinese (Traditional Han, Macau SAR China)",
		zh_Hant_TW: "Chinese (Traditional Han, Taiwan)",
		zh: "Chinese",
		kw_GB: "Cornish (United Kingdom)",
		kw: "Cornish",
		hr_HR: "Croatian (Croatia)",
		hr: "Croatian",
		cs_CZ: "Czech (Czech Republic)",
		cs: "Czech",
		da_DK: "Danish (Denmark)",
		da: "Danish",
		nl_BE: "Dutch (Belgium)",
		nl_NL: "Dutch (Netherlands)",
		nl: "Dutch",
		ebu_KE: "Embu (Kenya)",
		ebu: "Embu",
		en_AS: "English (American Samoa)",
		en_AU: "English (Australia)",
		en_BE: "English (Belgium)",
		en_BZ: "English (Belize)",
		en_BW: "English (Botswana)",
		en_CA: "English (Canada)",
		en_GU: "English (Guam)",
		en_HK: "English (Hong Kong SAR China)",
		en_IN: "English (India)",
		en_IE: "English (Ireland)",
		en_JM: "English (Jamaica)",
		en_MT: "English (Malta)",
		en_MH: "English (Marshall Islands)",
		en_MU: "English (Mauritius)",
		en_NA: "English (Namibia)",
		en_NZ: "English (New Zealand)",
		en_MP: "English (Northern Mariana Islands)",
		en_PK: "English (Pakistan)",
		en_PH: "English (Philippines)",
		en_SG: "English (Singapore)",
		en_ZA: "English (South Africa)",
		en_TT: "English (Trinidad and Tobago)",
		en_UM: "English (U.S. Minor Outlying Islands)",
		en_VI: "English (U.S. Virgin Islands)",
		en_GB: "English (United Kingdom)",
		en_US: "English (United States)",
		en_ZW: "English (Zimbabwe)",
		en: "English",
		eo: "Esperanto",
		et_EE: "Estonian (Estonia)",
		et: "Estonian",
		ee_GH: "Ewe (Ghana)",
		ee_TG: "Ewe (Togo)",
		ee: "Ewe",
		fo_FO: "Faroese (Faroe Islands)",
		fo: "Faroese",
		fil_PH: "Filipino (Philippines)",
		fil: "Filipino",
		fi_FI: "Finnish (Finland)",
		fi: "Finnish",
		fr_BE: "French (Belgium)",
		fr_BJ: "French (Benin)",
		fr_BF: "French (Burkina Faso)",
		fr_BI: "French (Burundi)",
		fr_CM: "French (Cameroon)",
		fr_CA: "French (Canada)",
		fr_CF: "French (Central African Republic)",
		fr_TD: "French (Chad)",
		fr_KM: "French (Comoros)",
		fr_CG: "French (Congo - Brazzaville)",
		fr_CD: "French (Congo - Kinshasa)",
		fr_CI: "French (Cote d'Ivoire)",
		fr_DJ: "French (Djibouti)",
		fr_GQ: "French (Equatorial Guinea)",
		fr_FR: "French (France)",
		fr_GA: "French (Gabon)",
		fr_GP: "French (Guadeloupe)",
		fr_GN: "French (Guinea)",
		fr_LU: "French (Luxembourg)",
		fr_MG: "French (Madagascar)",
		fr_ML: "French (Mali)",
		fr_MQ: "French (Martinique)",
		fr_MC: "French (Monaco)",
		fr_NE: "French (Niger)",
		fr_RW: "French (Rwanda)",
		fr_RE: "French (Reunion)",
		fr_BL: "French (Saint Barthelemy)",
		fr_MF: "French (Saint Martin)",
		fr_SN: "French (Senegal)",
		fr_CH: "French (Switzerland)",
		fr_TG: "French (Togo)",
		fr: "French",
		ff_SN: "Fulah (Senegal)",
		ff: "Fulah",
		gl_ES: "Galician (Spain)",
		gl: "Galician",
		lg_UG: "Ganda (Uganda)",
		lg: "Ganda",
		ka_GE: "Georgian (Georgia)",
		ka: "Georgian",
		de_AT: "German (Austria)",
		de_BE: "German (Belgium)",
		de_DE: "German (Germany)",
		de_LI: "German (Liechtenstein)",
		de_LU: "German (Luxembourg)",
		de_CH: "German (Switzerland)",
		de: "German",
		el_CY: "Greek (Cyprus)",
		el_GR: "Greek (Greece)",
		el: "Greek",
		gu_IN: "Gujarati (India)",
		gu: "Gujarati",
		guz_KE: "Gusii (Kenya)",
		guz: "Gusii",
		ha_Latn: "Hausa (Latin)",
		ha_Latn_GH: "Hausa (Latin, Ghana)",
		ha_Latn_NE: "Hausa (Latin, Niger)",
		ha_Latn_NG: "Hausa (Latin, Nigeria)",
		ha: "Hausa",
		haw_US: "Hawaiian (United States)",
		haw: "Hawaiian",
		he_IL: "Hebrew (Israel)",
		he: "Hebrew",
		hi_IN: "Hindi (India)",
		hi: "Hindi",
		hu_HU: "Hungarian (Hungary)",
		hu: "Hungarian",
		is_IS: "Icelandic (Iceland)",
		is: "Icelandic",
		ig_NG: "Igbo (Nigeria)",
		ig: "Igbo",
		id_ID: "Indonesian (Indonesia)",
		id: "Indonesian",
		ga_IE: "Irish (Ireland)",
		ga: "Irish",
		it_IT: "Italian (Italy)",
		it_CH: "Italian (Switzerland)",
		it: "Italian",
		ja_JP: "Japanese (Japan)",
		ja: "Japanese",
		kea_CV: "Kabuverdianu (Cape Verde)",
		kea: "Kabuverdianu",
		kab_DZ: "Kabyle (Algeria)",
		kab: "Kabyle",
		kl_GL: "Kalaallisut (Greenland)",
		kl: "Kalaallisut",
		kln_KE: "Kalenjin (Kenya)",
		kln: "Kalenjin",
		kam_KE: "Kamba (Kenya)",
		kam: "Kamba",
		kn_IN: "Kannada (India)",
		kn: "Kannada",
		kk_Cyrl: "Kazakh (Cyrillic)",
		kk_Cyrl_KZ: "Kazakh (Cyrillic, Kazakhstan)",
		kk: "Kazakh",
		km_KH: "Khmer (Cambodia)",
		km: "Khmer",
		ki_KE: "Kikuyu (Kenya)",
		ki: "Kikuyu",
		rw_RW: "Kinyarwanda (Rwanda)",
		rw: "Kinyarwanda",
		kok_IN: "Konkani (India)",
		kok: "Konkani",
		ko_KR: "Korean (South Korea)",
		ko: "Korean",
		khq_ML: "Koyra Chiini (Mali)",
		khq: "Koyra Chiini",
		ses_ML: "Koyraboro Senni (Mali)",
		ses: "Koyraboro Senni",
		lag_TZ: "Langi (Tanzania)",
		lag: "Langi",
		lv_LV: "Latvian (Latvia)",
		lv: "Latvian",
		lt_LT: "Lithuanian (Lithuania)",
		lt: "Lithuanian",
		luo_KE: "Luo (Kenya)",
		luo: "Luo",
		luy_KE: "Luyia (Kenya)",
		luy: "Luyia",
		mk_MK: "Macedonian (Macedonia)",
		mk: "Macedonian",
		jmc_TZ: "Machame (Tanzania)",
		jmc: "Machame",
		kde_TZ: "Makonde (Tanzania)",
		kde: "Makonde",
		mg_MG: "Malagasy (Madagascar)",
		mg: "Malagasy",
		ms_BN: "Malay (Brunei)",
		ms_MY: "Malay (Malaysia)",
		ms: "Malay",
		ml_IN: "Malayalam (India)",
		ml: "Malayalam",
		mt_MT: "Maltese (Malta)",
		mt: "Maltese",
		gv_GB: "Manx (United Kingdom)",
		gv: "Manx",
		mr_IN: "Marathi (India)",
		mr: "Marathi",
		mas_KE: "Masai (Kenya)",
		mas_TZ: "Masai (Tanzania)",
		mas: "Masai",
		mer_KE: "Meru (Kenya)",
		mer: "Meru",
		mfe_MU: "Morisyen (Mauritius)",
		mfe: "Morisyen",
		naq_NA: "Nama (Namibia)",
		naq: "Nama",
		ne_IN: "Nepali (India)",
		ne_NP: "Nepali (Nepal)",
		ne: "Nepali",
		nd_ZW: "North Ndebele (Zimbabwe)",
		nd: "North Ndebele",
		nb_NO: "Norwegian Bokmal (Norway)",
		nb: "Norwegian Bokmal",
		nn_NO: "Norwegian Nynorsk (Norway)",
		nn: "Norwegian Nynorsk",
		nyn_UG: "Nyankole (Uganda)",
		nyn: "Nyankole",
		or_IN: "Oriya (India)",
		or: "Oriya",
		om_ET: "Oromo (Ethiopia)",
		om_KE: "Oromo (Kenya)",
		om: "Oromo",
		ps_AF: "Pashto (Afghanistan)",
		ps: "Pashto",
		fa_AF: "Persian (Afghanistan)",
		fa_IR: "Persian (Iran)",
		fa: "Persian",
		pl_PL: "Polish (Poland)",
		pl: "Polish",
		pt_BR: "Portuguese (Brazil)",
		pt_GW: "Portuguese (Guinea-Bissau)",
		pt_MZ: "Portuguese (Mozambique)",
		pt_PT: "Portuguese (Portugal)",
		pt: "Portuguese",
		pa_Arab: "Punjabi (Arabic)",
		pa_Arab_PK: "Punjabi (Arabic, Pakistan)",
		pa_Guru: "Punjabi (Gurmukhi)",
		pa_Guru_IN: "Punjabi (Gurmukhi, India)",
		pa: "Punjabi",
		ro_MD: "Romanian (Moldova)",
		ro_RO: "Romanian (Romania)",
		ro: "Romanian",
		rm_CH: "Romansh (Switzerland)",
		rm: "Romansh",
		rof_TZ: "Rombo (Tanzania)",
		rof: "Rombo",
		ru_MD: "Russian (Moldova)",
		ru_RU: "Russian (Russia)",
		ru_UA: "Russian (Ukraine)",
		ru: "Russian",
		rwk_TZ: "Rwa (Tanzania)",
		rwk: "Rwa",
		saq_KE: "Samburu (Kenya)",
		saq: "Samburu",
		sg_CF: "Sango (Central African Republic)",
		sg: "Sango",
		seh_MZ: "Sena (Mozambique)",
		seh: "Sena",
		sr_Cyrl: "Serbian (Cyrillic)",
		sr_Cyrl_BA: "Serbian (Cyrillic, Bosnia and Herzegovina)",
		sr_Cyrl_ME: "Serbian (Cyrillic, Montenegro)",
		sr_Cyrl_RS: "Serbian (Cyrillic, Serbia)",
		sr_Latn: "Serbian (Latin)",
		sr_Latn_BA: "Serbian (Latin, Bosnia and Herzegovina)",
		sr_Latn_ME: "Serbian (Latin, Montenegro)",
		sr_Latn_RS: "Serbian (Latin, Serbia)",
		sr: "Serbian",
		sn_ZW: "Shona (Zimbabwe)",
		sn: "Shona",
		ii_CN: "Sichuan Yi (China)",
		ii: "Sichuan Yi",
		si_LK: "Sinhala (Sri Lanka)",
		si: "Sinhala",
		sk_SK: "Slovak (Slovakia)",
		sk: "Slovak",
		sl_SI: "Slovenian (Slovenia)",
		sl: "Slovenian",
		xog_UG: "Soga (Uganda)",
		xog: "Soga",
		so_DJ: "Somali (Djibouti)",
		so_ET: "Somali (Ethiopia)",
		so_KE: "Somali (Kenya)",
		so_SO: "Somali (Somalia)",
		so: "Somali",
		es_AR: "Spanish (Argentina)",
		es_BO: "Spanish (Bolivia)",
		es_CL: "Spanish (Chile)",
		es_CO: "Spanish (Colombia)",
		es_CR: "Spanish (Costa Rica)",
		es_DO: "Spanish (Dominican Republic)",
		es_EC: "Spanish (Ecuador)",
		es_SV: "Spanish (El Salvador)",
		es_GQ: "Spanish (Equatorial Guinea)",
		es_GT: "Spanish (Guatemala)",
		es_HN: "Spanish (Honduras)",
		es_419: "Spanish (Latin America)",
		es_MX: "Spanish (Mexico)",
		es_NI: "Spanish (Nicaragua)",
		es_PA: "Spanish (Panama)",
		es_PY: "Spanish (Paraguay)",
		es_PE: "Spanish (Peru)",
		es_PR: "Spanish (Puerto Rico)",
		es_ES: "Spanish (Spain)",
		es_US: "Spanish (United States)",
		es_UY: "Spanish (Uruguay)",
		es_VE: "Spanish (Venezuela)",
		es: "Spanish",
		sw_KE: "Swahili (Kenya)",
		sw_TZ: "Swahili (Tanzania)",
		sw: "Swahili",
		sv_FI: "Swedish (Finland)",
		sv_SE: "Swedish (Sweden)",
		sv: "Swedish",
		gsw_CH: "Swiss German (Switzerland)",
		gsw: "Swiss German",
		shi_Latn: "Tachelhit (Latin)",
		shi_Latn_MA: "Tachelhit (Latin, Morocco)",
		shi_Tfng: "Tachelhit (Tifinagh)",
		shi_Tfng_MA: "Tachelhit (Tifinagh, Morocco)",
		shi: "Tachelhit",
		dav_KE: "Taita (Kenya)",
		dav: "Taita",
		ta_IN: "Tamil (India)",
		ta_LK: "Tamil (Sri Lanka)",
		ta: "Tamil",
		te_IN: "Telugu (India)",
		te: "Telugu",
		teo_KE: "Teso (Kenya)",
		teo_UG: "Teso (Uganda)",
		teo: "Teso",
		th_TH: "Thai (Thailand)",
		th: "Thai",
		bo_CN: "Tibetan (China)",
		bo_IN: "Tibetan (India)",
		bo: "Tibetan",
		ti_ER: "Tigrinya (Eritrea)",
		ti_ET: "Tigrinya (Ethiopia)",
		ti: "Tigrinya",
		to_TO: "Tonga (Tonga)",
		to: "Tonga",
		tr_TR: "Turkish (Turkey)",
		tr: "Turkish",
		uk_UA: "Ukrainian (Ukraine)",
		uk: "Ukrainian",
		ur_IN: "Urdu (India)",
		ur_PK: "Urdu (Pakistan)",
		ur: "Urdu",
		uz_Arab: "Uzbek (Arabic)",
		uz_Arab_AF: "Uzbek (Arabic, Afghanistan)",
		uz_Cyrl: "Uzbek (Cyrillic)",
		uz_Cyrl_UZ: "Uzbek (Cyrillic, Uzbekistan)",
		uz_Latn: "Uzbek (Latin)",
		uz_Latn_UZ: "Uzbek (Latin, Uzbekistan)",
		uz: "Uzbek",
		vi_VN: "Vietnamese (Vietnam)",
		vi: "Vietnamese",
		vun_TZ: "Vunjo (Tanzania)",
		vun: "Vunjo",
		cy_GB: "Welsh (United Kingdom)",
		cy: "Welsh",
		yo_NG: "Yoruba (Nigeria)",
		yo: "Yoruba",
		zu_ZA: "Zulu (South Africa)",
		zu: "Zulu"
	}
}

export function customExtends(obj) {
	var length = arguments.length;
	if (length < 2 || obj == null) return obj;
	for (var index = length-1; index > 0; index--) {
		var source = arguments[index];
		var proto = Object.getPrototypeOf(source);
		var keys = Object.getOwnPropertyNames(proto);
		for (var key in keys) {
		  var methodName = keys[key];
		  if (obj[methodName] === void 0) obj[methodName] = proto[methodName];
		}
	}
	return obj;
}

export function customExtendClass(cls){
	var length = arguments.length;
	if (length < 2 || cls == null) return cls;
	for (var index = length-1; index > 0; index--) {
		var source = arguments[index];
		var proto = Object.getPrototypeOf(source);
		var keys = Object.getOwnPropertyNames(proto);
		for (var key in keys) {
		  var methodName = keys[key];
		  if (cls.prototype[methodName] === void 0) cls.prototype[methodName] = proto[methodName];
		}
		keys =  Object.getOwnPropertyNames(source.constructor);
		for (var key in keys) {
			var methodName = keys[key];
			if (cls[methodName] === void 0) cls[methodName] = source.constructor[methodName];
		}
	}
}
export function customExtendStaticMethods(cls){
	var length = arguments.length;
	if (length < 2 || cls == null) return cls;
	for (var index = length-1; index > 0; index--) {
		var source = arguments[index];
		var keys =  Object.getOwnPropertyNames(source);
		for (var key in keys) {
			var methodName = keys[key];
			if (cls[methodName] === void 0) cls[methodName] = source[methodName];
		}
	}
}
export function inheritSuperModelRelations(model,type, superType){
	if (superType.prototype.relations) {
		// Find relations that exist on the '_superModel', but not yet on this model.
		var inheritedRelations = _.filter(superType.prototype.relations || [], function (superRel) {
			return !_.any(type.relations || [], function (rel) {
				return superRel.relatedModel === rel.relatedModel && superRel.key === rel.key;
			}, type);
		}, type);
		type.relations = inheritedRelations.concat(type.relations);
		// when ported to latest version of backbone, this will not be required,as we call this in preinitialize
		for(var i=0;i<inheritedRelations.length;i++){
			Backbone.Relational.store.initializeRelation(model, inheritedRelations[i], {});
		}
	}
}

export function httpRequest(method, url, callback, params){
	var request = new XMLHttpRequest();
	request.onload = function (data) {
		if (request.response) {
			callback(request.response);
		}
	};
	request.onerror = function (data) {
		if (this.status == 0 || this.status == "404") {
			callback(false);
		}
	};
	request.open(method,url, true);
	request.setRequestHeader('Content-Type', 'application/json');
	//request.setRequestHeader('Accept', 'application/json');
	//request.setRequestHeader('Access-Control-Allow-Methods' , 'GET, POST, OPTIONS');

	request.setRequestHeader('Access-Control-Allow-Origin', '*');
	request.setRequestHeader('Access-Control-Allow-Credentials', 'true');
	request.setRequestHeader("Access-Control-Allow-Headers", "Authorization, Origin, X-Requested-With, Content-Type, Accept");
	//request.setRequestHeader("Content-Type", "application/json", true);
	var paramsString = params ? JSON.stringify(params) : null;
	request.send(paramsString);
}

export function getCurrentView(){
	var currentView = null;
	if(!currentView && window.vdmModelView.ValueStreamMapDesignerViewModel){
		currentView = window.vdmModelView.ValueStreamMapDesignerViewModel;
	} else if(!currentView && window.vdmModelView.StrategyMapDesignerViewModel){
		currentView = window.vdmModelView.StrategyMapDesignerViewModel;
	} else if(!currentView && window.vdmModelView.EcoMapDesignerViewModel){
		currentView = window.vdmModelView.EcoMapDesignerViewModel;
	} else if(!currentView && window.vdmModelView.CapabilityLibraryDiagramViewModel){
		currentView = window.vdmModelView.CapabilityLibraryDiagramViewModel;
	} else if(!currentView && window.vdmModelView.BusinessModelViewModel){
		currentView = window.vdmModelView.BusinessModelViewModel;
	} else if(!currentView && window.vdmModelView.BMCubeViewModel){
		currentView = window.vdmModelView.BMCubeViewModel;
	} else if(!currentView && window.vdmModelView.SummernoteReportViewModel){
		currentView = window.vdmModelView.SummernoteReportViewModel;
	} else if(!currentView && window.vdmModelView.DashboardViewModel){
		currentView = window.vdmModelView.DashboardViewModel;
	} else if(!currentView && window.vdmModelView.PlanViewModel){
		currentView = window.vdmModelView.PlanViewModel;
	}
	if(currentView){
		if(jQuery("#NewWizard").length > 0){
			jQuery("#NewWizard").find(".bootbox-close-button").click();
		}
		jQuery("#newModal").children().each(function(child){
			jQuery("#"+this.id).find("[data-dismiss]")[0].click();
		});
		if(jQuery(".bootbox").length > 0 && jQuery(".bootbox").find(".bootbox-close-button").length > 0){
			jQuery(".bootbox").find(".bootbox-close-button").click();
		}		
	}
	return currentView;
}

export function updateDiagram(diagModel, del){
	var currentView = getCurrentView();
	if(!window.myWorker && currentView && currentView.model == diagModel){
		currentView.enableComplete(false);
		if(del){
			window.utils.navigatePage();
		}else {
			currentView.afterRenderView(true);
		}
	}
}

export function invokeGetDefaultDirectory(workspaceStore, callback, altId) {	//doing this as any times directory reader is not erroring out.
	try {
		window.getDefaultRepository(workspaceStore, function (workspace) {
			callback(workspace);
		}, altId, altId, true);
	} catch (e) {
		console.log(e);
		setTimeout(function(){invokeGetDefaultDirectory(workspaceStore, callback, altId)}, 2000);
	}
}

export function updateUIReport(diagModel, del){
	var currentView = getCurrentView();
	if(!window.myWorker && currentView && currentView.model == diagModel){
		if(del){
			window.utils.navigatePage();
		}else {
			window.utils.startSpinner('reportSpinner');
			currentView.afterRenderView(true,currentView);
		}
	}
}
export function updatePhaseView(phaseModel, del){
	var currentView = getCurrentView();
	if(!window.myWorker && currentView && currentView.model.id == phaseModel.getNestedParent().id){
		if(del){
			window.utils.navigatePage();
		}else {
			currentView.initializeSearch(currentView.parentOptions ? currentView.parentOptions.searchValue : null);
		}
	}
}

export function updateUIDashBoard(dashModel, del){
	var currentView = getCurrentView();
	if(!window.myWorker && currentView && currentView.model.getParent() == dashModel){
		if(del){
			window.utils.navigatePage();
		}else {
			window.utils.startSpinner('refreshCharts');
			currentView.afterRenderView();
		}
	}
}


export function updateUIActivity(actModel, del){
	var currentView = getCurrentView();
	if(!window.myWorker && currentView){// to avoid for current person
		if(del && (currentView == window.vdmModelView.ValueStreamMapDesignerViewModel || currentView == window.vdmModelView.StrategyMapDesignerViewModel)){
			var actExists = currentView.model.get('activity').findWhere({ 'id': actModel.id });
			if(actExists){
				currentView.completeButton(false);
				currentView.afterRenderView(true);
			}
		} else if(currentView == window.vdmModelView.BusinessModelViewModel || currentView == window.vdmModelView.BMCubeViewModel){
			var actExists = currentView.model.get('bmActivity').findWhere({ 'activity': actModel });
			if(actExists && currentView.cancelActivity){
				currentView.cancelActivity(true);
			} else if(actExists && currentView.fillValuesTab){
				currentView.fillValuesTab();
			}
		}
	}
}
export function updateUIValueProposition(vpModel, myVp, del){
	var currentView = getCurrentView();
	if(!window.myWorker && currentView){// to avoid for current person
		if(del && (currentView == window.vdmModelView.ValueStreamMapDesignerViewModel || currentView == window.vdmModelView.StrategyMapDesignerViewModel)){
			var vpExists = currentView.model.get('valueProposition').findWhere({ 'id': vpModel.id });
			if(vpExists){
				currentView.completeButton(false);
				currentView.afterRenderView(true);
			}
		} else if(currentView == window.vdmModelView.BusinessModelViewModel || currentView == window.vdmModelView.BMCubeViewModel){
			var vpExists = null;
			if(myVp){
				vpExists = currentView.model.get('bmValueFormula').findWhere({ 'valueFormula': vpModel });
			}else {
				vpExists = currentView.model.get('bmValueProposition').findWhere({ 'valueProposition': vpModel });
			}
			if(vpExists && currentView.resetTables){
				currentView.resetTables();
			} else if(vpExists && currentView.fillValuesTab){
				currentView.fillValuesTab();
			}
		}
	}
}
export function initializeSlick(ele) {
	var slickOptions = {
		dots: false,
		infinite: false,
		speed: 300,
		slidesToShow: 5,
		slidesToScroll: 5,
		arrows: true,
		responsive: [
			{
				breakpoint: 2048,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 5
				}
			},
			{
				breakpoint: 1400,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4
				}
			},
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 700,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 520,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	ele.slick(slickOptions);
}

export function createDialog(title, videos, checkbox) {
    const outerContainer = document.createElement('div');
    const dialogContainer = document.createElement('div');
    if (videos.length > 3) {
        dialogContainer.style.overflowY = 'scroll';
        dialogContainer.style.height = '70vh';
        dialogContainer.style.marginRight = '-10px';
    }

    const videoGrid = document.createElement('div');
    videoGrid.style.display = 'grid';

    const numVideos = videos.length;
    let columns = Math.min(4, Math.ceil(numVideos / Math.ceil(numVideos / 4)));

    const mediaQuery = window.matchMedia('(max-width: 600px)');
    if (mediaQuery.matches) {
        columns = 1;
    }

    videoGrid.style.gridTemplateColumns = `repeat(${columns}, 1fr)`;

    videos.forEach(video => {
        const videoContainer = document.createElement('div');
        videoContainer.style.display = 'flex';
        videoContainer.style.flexDirection = 'column';
        videoContainer.style.alignItems = 'center';
        videoContainer.style.padding = '10px';
        videoContainer.style.boxSizing = 'border-box';

        const thumbnailContainer = document.createElement('div');
        thumbnailContainer.style.position = 'relative';
        thumbnailContainer.style.cursor = 'pointer';

        const thumbnail = document.createElement('img');
        thumbnail.src = `https://img.youtube.com/vi/${video.id}/maxresdefault.jpg`;
        thumbnail.style.width = video.width || '100%';
        thumbnail.style.boxShadow = '3px 3px 10px 0 black';
        thumbnail.style.borderRadius = '8px';

        const playIcon = document.createElement('div');
        playIcon.style.position = 'absolute';
        playIcon.style.top = '50%';
        playIcon.style.left = '50%';
        playIcon.style.transform = 'translate(-50%, -50%)';
        playIcon.innerHTML = `
		<svg height="70px" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
			<g id="_x33_95-youtube">
				<g>
					<path d="M476.387,144.888c-5.291-19.919-20.878-35.608-40.67-40.933C399.845,94.282,256,94.282,256,94.282
						s-143.845,0-179.719,9.674c-19.791,5.325-35.378,21.013-40.668,40.933c-9.612,36.105-9.612,111.438-9.612,111.438
						s0,75.334,9.612,111.438c5.29,19.92,20.877,34.955,40.668,40.281C112.155,417.719,256,417.719,256,417.719
						s143.845,0,179.717-9.674c19.792-5.326,35.379-20.361,40.67-40.281c9.612-36.104,9.612-111.438,9.612-111.438
						S485.999,180.994,476.387,144.888z" style="fill:#FF0000;"/>
					<polygon points="208.954,324.723 208.954,187.93 329.18,256.328" style="fill:#FFFFFF;"/>
				</g>
			</g>
			<g id="Layer_1"/>
		</svg>
	`;
        playIcon.style.pointerEvents = 'none';

        thumbnailContainer.onclick = function() {
            const iframe = document.createElement('iframe');
            iframe.style.width = video.width || '100%';
            iframe.style.height = video.height || 'auto';
            iframe.src = `https://www.youtube-nocookie.com/embed/${video.id}?autoplay=1&rel=0`;
            iframe.title = video.title;
            iframe.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share';
            iframe.referrerPolicy = 'strict-origin-when-cross-origin';
            iframe.allowFullscreen = true;
            videoContainer.replaceChild(iframe, thumbnailContainer);
        };

        thumbnailContainer.appendChild(thumbnail);
        thumbnailContainer.appendChild(playIcon);
        videoContainer.appendChild(thumbnailContainer);

        const videoTitle = document.createElement('h4');
        videoTitle.style.fontFamily = 'Roboto';
        videoTitle.style.textAlign = 'center';
        videoTitle.style.marginTop = '10px';
        videoTitle.textContent = video.title;

        const videoDescription = document.createElement('h4');
        videoDescription.style.fontFamily = 'Roboto';
        videoDescription.style.textAlign = 'center';
        videoDescription.style.fontSize = 'medium';
		videoDescription.style.width = video.width;
        videoDescription.textContent = video.description;
		
		playIcon.style.opacity = '0';
		thumbnailContainer.onmouseover = function() {
		    playIcon.style.opacity = '1';
		};
		thumbnailContainer.onmouseout = function() {
		    playIcon.style.opacity = '0';
		};

        if (video.title) {
            videoContainer.appendChild(videoTitle);
        }
        if (video.description && video.title) {
            videoDescription.style.marginTop = '0px';
            videoDescription.style.marginBottom = '0px';
            videoContainer.appendChild(videoDescription);
        } else {
            videoContainer.appendChild(videoDescription);
        }
        videoGrid.appendChild(videoContainer);
    });
    dialogContainer.appendChild(videoGrid);
    outerContainer.appendChild(dialogContainer);

    const minVideos = 1;
    const maxVideos = 10;
    var dialogWidth = 30 + ((numVideos - minVideos) * (60 - 30) / (maxVideos - minVideos));
    dialogWidth = Math.max(30, Math.min(80, dialogWidth));

    const styleElement = document.createElement('style');
    styleElement.textContent = `
        .video-bootbox > .modal-dialog {
            width: ${dialogWidth}%;
            max-width: 90vw;
        }
        @media (max-width: 600px) {
            .video-bootbox > .modal-dialog {
                width: 90%;
            }
        }`;
    document.head.appendChild(styleElement);
    if (checkbox) {
        let horizontalLine = document.createElement('hr');
        horizontalLine.style.borderTop = '1px solid black';
        horizontalLine.style.marginTop = '0px';
        const checkboxContainer = document.createElement('div');
        checkboxContainer.style.cssText = 'display: flex; align-items: center; margin-top: 10px;';
        const checkboxLabel = document.createElement('label');
        checkboxLabel.style.fontFamily = 'Roboto';
        checkboxLabel.style.cursor = 'pointer';
        const checkboxInput = document.createElement('input');
        checkboxInput.type = 'checkbox';
        checkboxInput.id = 'videoCheckbox';
        checkboxInput.style.cursor = 'pointer';
        checkboxInput.style.marginRight = '5px';
        checkboxLabel.appendChild(checkboxInput);
        checkboxLabel.appendChild(document.createTextNode("Don't show this again"));
        checkboxContainer.appendChild(checkboxLabel);
        outerContainer.appendChild(horizontalLine);
        outerContainer.appendChild(checkboxContainer);
    }

    bootbox.dialog({
        title: title,
        message: outerContainer,
        onEscape: function() {},
        size: 'large',
        className: 'video-bootbox',
        callback: function() {
            const bootboxDialog = document.querySelector('.video-bootbox > .modal-dialog');
            if (bootboxDialog) {
                bootboxDialog.style.width = dialogWidth;
                bootboxDialog.style.maxWidth = '100%'; 
                bootboxDialog.style.overflowY = 'auto';
                bootboxDialog.style.maxHeight = '90vh';
            }
        }
    });
}

export function getMonthFromDate(dat) {
	return dat.toLocaleString("en-GB", { month: "long" });
}