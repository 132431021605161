import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {EObject} from '../cmof/EObject'
import {EObjectMixin} from '../cmof/EObjectMixin'
import {Tag} from '../cmof/Tag'
import {Ticket} from '../tickets/Ticket'
import {User} from '../tickets/User'
import {BeepPackage} from './BeepPackage'
import {Element} from '../cmof/Element'
import {asyncsimple} from '../../../../libs/asyncsimple/asyncsimple'
import {PackageReference}  from '../../../com/vbee/filesystem/PackageReference'
//import {PackageReference as PlanPackageReference}  from '../transformation/PackageReference'

//define(["require","jquery","underscore","async","backbone","Lawnchair","backbone-lawnchair","appcommon/com/vbee/data/DataManager","app/global" ,"appbo/cmof/EObject","appbo/cmof/EObjectMixin","asyncsimple"],
//function(require,jQuery,_,async, Backbone,Lawnchair, backboneLawnchair,DataManager,global,EObject,EObjectMixin,asyncsimple
/*, PlanPackageReference
, PackageReference
, ScenarioProxy
, Ticket
, User
, Tag){
	
	if(!Ticket){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.Ticket){
			Ticket = importPath.Ticket;
		}
		else{
			require(["appbo/tickets/Ticket"],function(loadedModule){
				Ticket = loadedModule;
			});
			import('../tickets/Ticket').then(loadedModule => {
				  Ticket = loadedModule;
            })
		}
	}
	if(!User){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.User){
			User = importPath.User;
		}
		else{
			require(["appbo/tickets/User"],function(loadedModule){
				User = loadedModule;
			});
		}
	}
	if(!User){
		var importPath = DataManager.getDataManager().buildAppNsPath("tickets",global.version);
		if(importPath.User){
			User = importPath.User;
		}
		else{
			require(["appbo/tickets/User"],function(loadedModule){
				User = loadedModule;
			});
		}
	}
	if(!Tag){
		var importPath = DataManager.getDataManager().buildAppNsPath("cmof",global.version);
		if(importPath.Tag){
			Tag = importPath.Tag;
		}
		else{
			require(["appbo/cmof/Tag"],function(loadedModule){
				Tag = loadedModule;
			});
		}
	}
	if(!PlanPackageReference){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.PackageReference){
			PlanPackageReference = importPath.PackageReference;
		}
		else{
			require(["appbo/transformation/PackageReference"],function(loadedModule){
				PlanPackageReference = loadedModule;
			});
		}
	}
	if(!ScenarioProxy){
		var importPath = DataManager.getDataManager().buildAppNsPath("transformation",global.version);
		if(importPath.ScenarioProxy){
			ScenarioProxy = importPath.ScenarioProxy;
		}
		else{
			require(["appbo/transformation/ScenarioProxy"],function(loadedModule){
				ScenarioProxy = loadedModule;
			});
		}
	}	
	if(!PackageReference){
		var importPath = DataManager.getDataManager().buildCommonNsPath("com/vbee/filesystem",global.version);
		if(importPath.PackageReference){
			PackageReference = importPath.PackageReference;
		}
		else{
			require(["appcommon/com/vbee/filesystem/PackageReference"],function(loadedModule){
				PackageReference = loadedModule;
			});
		}
	}*/
	export class BeepPackageMixin{
		
		constructor(){
			var path = DataManager.getDataManager().buildAppNsPath("beeppackage",global.version);
			path.BeepPackageMixin = BeepPackageMixin;
		}
	
		defaults(){
			var ret = {
				type: "beeppackage_BeepPackage",
				version:DataManager.getDataManager().get('currentVDMVersion')
			};
			return jQuery.extend(EObject.prototype.defaults.apply(this),ret);
		}
		static getSubModelTypes(){
			return {
				smm_SmmModel : 'smm.SmmModel',
				transformation_Plan : 'transformation.Plan',
				vdml_ValueDeliveryModel : 'vdml.ValueDeliveryModel',
				report_BeepReport: 'report.BeepReport',
				ecomap_EcoMap: 'ecomap.EcoMap',
				dashboard_Analytics: 'dashboard.Analytics',
				concept_BeepVocabulary : 'concept.BeepVocabulary',
				beeppackage_BeepModule : 'beeppackage.BeepModule',
				beeppackage_CodeContainer : 'beeppackage.CodeContainer'
			};
		}
		static getMixinRelations(){
			return _.union([
			{
				type :Backbone.HasMany,
				containingClass:"beeppackage_BeepPackage",
				key:"assignedTicket",
				relatedModel:"tickets.Ticket",
				reverseRelation: {
					key:"ticketOwner",
					type :Backbone.HasOne,
					includeInJSON:Backbone.Model.prototype.idAttribute
				}
			},
			{
				type :Backbone.HasMany,
				containingClass:"beeppackage_BeepPackage",
				key:"owner",
				relatedModel:"tickets.User",
				includeInJSON: Backbone.Model.prototype.idAttribute,
			},
			{
				type :Backbone.HasMany,
				containingClass:"beeppackage_BeepPackage",
				key:"user",
				relatedModel:"tickets.User",
				reverseRelation: {
					key:"userOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			},
			{
				type :Backbone.HasMany,
				containingClass:"beeppackage_BeepPackage",
				key:"dependentPackage",
				relatedModel:"beeppackage.BeepPackage",
				includeInJSON: Backbone.Model.prototype.idAttribute,
			},
			{
				type :Backbone.HasMany,
				containingClass:"beeppackage_BeepPackage",
				key:"ownedTag",
				relatedModel:"cmof.Tag",
				reverseRelation: {
					key:"tagOwner",
					type :Backbone.HasOne,
					includeInJSON:"id"
				}
			}
			])
		}
		static getCumulativeMixinRelations(){
			var eObjRelations = EObjectMixin.getCumulativeMixinRelations();
			var ret = _.union(BeepPackageMixin.getMixinRelations(),eObjRelations);
			eObjRelations.length = 0;
			return ret;
		};
		static getSuperTypes(){
			return [
				"cmof_EObject"
			];
		}
		static getProperties(){
			return [
				{ name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
				{name : "version",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
				{name : "documentVersion",type : "EInteger",defaultValue : 0,containingClass : "beeppackage_BeepPackage" },
				{name : "label",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
				{name : "parentLabel",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
				{name : "syncedChangeLog",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
				{name : "synchedChangeSet",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
				{name : "copySource",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
				{name : "copyTimeStamp",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
				{name : "copyChangeSet",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" }
			]
		}
		getParent(){
			var container;
			return EObject.prototype.getParent.apply(this, arguments);
		}
		getPackagePath(path){
			if(!path){
				path = [];
			}
			return EObject.prototype.getPackagePath.apply(this, arguments);
		}
		static getViewProperties(type) {
			return {
				templatePath : "views/beeppackage/views/properties/BeepPackagePropertiesTemplate.html",
				templateName : "BeepPackagePropertiesTemplate",
				viewTypeStr : "appviews/beeppackage/views/properties/BeepPackageViewModel",
				tabId : "BeepPackageView",
				tabName: "BeepPackage"
			}
		}
	//#startCustomMethods	
		static getDialogViewProperties(type) {
			if (type && type === "Discover") {
				return {
					templatePath: "views/beeppackage/views/properties/DiscoverTemplate.html",
					templateName: "DiscoverTemplate",
					viewTypeStr: "appviews/beeppackage/views/properties/DiscoverViewModel",
					tabId: "DiscoverView",
					tabName: "Discover"
				}
			} else if (type && type === "Prototype") {
				return {
					templatePath: "views/beeppackage/views/properties/PrototypeTemplate.html",
					templateName: "PrototypeTemplate",
					viewTypeStr: "appviews/beeppackage/views/properties/PrototypeViewModel",
					tabId: "PrototypeView",
					tabName: "Prototype"
				}
			} else if (type && type === "Adopt") {
				return {
					templatePath: "views/beeppackage/views/properties/AdoptTemplate.html",
					templateName: "AdoptTemplate",
					viewTypeStr: "appviews/beeppackage/views/properties/AdoptViewModel",
					tabId: "AdoptView",
					tabName: "Adopt"
				}
			} else if (type && type === "Admin") {
				return {
					templatePath: "views/beeppackage/views/properties/AdminTemplate.html",
					templateName: "AdminTemplate",
					viewTypeStr: "appviews/beeppackage/views/properties/AdminViewModel",
					tabId: "AdminView",
					tabName: "Admin"
				}
			}
			return {
				templatePath: "views/beeppackage/views/properties/BeepPackagePropertiesTemplate.html",
				templateName: "BeepPackagePropertiesTemplate",
				viewTypeStr: "appviews/beeppackage/views/properties/BeepPackageViewModel",
				tabId: "BeepPackageView",
				tabName: "BeepPackage"
			}
		}

		checkUniqueName(name,modal,disallowedNames){
			var self = this;
			var unique = true;
			var existingColl = new Backbone.Collection;
			var modalId = window.utils.htmlEscape(modal.id);
			if(modal.get('type') == "vdml_Activity"){
				existingColl.push(modal.get('performingRole').get('collaborationRoleOwner').get('activity').models);
				
			} else if(modal.get('type') == "vdml_ValueStream"){
				modalId = window.utils.htmlEscape(modal.get('persuedProposition').id);
				existingColl.push(modal.get('persuedProposition').get('provider').get('providedProposition').models);
			} else if(modal.get('type') == "vdml_MarketSegment"){
				var colls = self.get('collaboration');
				for(var i=0;i<colls.length;i++){
					if(colls.at(i).get('type') === 'vdml_Community'){
						existingColl.push(colls.at(i));
					}
				}
			} else if( modal.get('type') == "vdml_Actor"){
				existingColl.push(self.get('actor').models);
			} else if(modal.get('type') == "vdml_BusinessNetwork"){
				var colls = self.get('collaboration');
				for(var i=0;i<colls.length;i++){
					if(colls.at(i).get('type') === 'vdml_BusinessNetwork'){
						existingColl.push(colls.at(i));
					}
				}
			} else if(modal.get('type') == "vdml_OrgUnit"){
				var colls = self.get('collaboration');
				for(var i=0;i<colls.length;i++){
					if(colls.at(i).get('type') === 'vdml_OrgUnit'){
						existingColl.push(colls.at(i));
					}
				}
			} else if(modal.get('type') == "vdml_BusinessItem"){
				var resourceStore = modal.getParent().get('ownedStore');
				for(var i=0;i<resourceStore.length;i++){
					existingColl.push(resourceStore.at(i).get('resource'));
				}
			} else if(modal.get('type') == "vdml_CapabilityOffer"){
				existingColl.push(modal.getParent().get('capabilityOffer').models);
			} else if(modal.get('type') == "vdml_ValueProposition"){
				var vps = modal.get('provider').get('providedProposition');
				for(var i=0;i<vps.length;i++){
					if(!vps.at(i).get('isValueFormula')){
						existingColl.push(vps.at(i));
					}
				}
			} else if(modal.get('type') == "vdml_ValueFormula"){
				var vps = modal.get('provider').get('providedProposition');
				for(var i=0;i<vps.length;i++){
					if(vps.at(i).get('isValueFormula')){
						existingColl.push(vps.at(i));
					}
				}
			} else if(modal.get('type') == "vdml_Party"){
				existingColl.push(modal.getParent().get('collaborationRole').models);
			} else if(modal.get('type') == "vdml_BusinessModel"){
				existingColl.push(modal.getParent().get('businessModel').models);
			} else if(modal.get('type') == "vdml_CapabilityDefinition" || modal.get('type') == "vdml_CapabilityCategory"){
				var capList = modal.get('capabilityOwner').get('capability');
				for(var i=0;i<capList.length;i++){
					if(capList.at(i).get('type') === modal.get('type')){
						existingColl.push(capList.at(i));
					}
				}
			} else if(modal.get('type') == "vdml_ValueAdd"){
				existingColl.push(modal.getParent().get('valueAdd').models);
			} else if(modal.get('type') == "vdml_ValuePropositionComponent"){
				existingColl.push(modal.getParent().get('component').models);
			}
			unique = window.isUniqueName(name, [], modalId, existingColl);
			if(unique && disallowedNames && disallowedNames.includes(name)){
				unique = false;
			}
			return unique;
		};

		createRevision(currentAlternativeId,copyAlternativeId,callback,skipDependentPackages){
				var self = this;
				function fetchAlternativeModel(altId){
					var plan = DataManager.getDataManager().get('currentPlan');
					for(var i=0;i<plan.get("phase").length;i++){
						var alternatives = plan.get("phase").models[i].get("phaseAlternative");
						var alt = alternatives.findWhere({id:altId});
						if(alt) {
							return alt;
						}
					}
				}
				var copyAlternative = fetchAlternativeModel(currentAlternativeId);
				var currentAlternative = fetchAlternativeModel(copyAlternativeId);
				self.copyToAlternative(currentAlternative,copyAlternative,function(vdmPackage){
					DataManager.getDataManager().loadAltScenarios(DataManager.getDataManager(),DataManager.getDataManager().get('currentPlan'),function(){
						DataManager.getDataManager().get('initializedPackages').add({ 'id': vdmPackage.get('id'), 'version': parseInt(vdmPackage.get('version')), 'model': vdmPackage });
						callback(vdmPackage);
					});
				},false,skipDependentPackages);
		};

		addDependentPackage(packs,copyPackages,copyAlternative,skipDependentPackages){
			var self = this;
			var pack;
			var packRet = packs.splice(0, 1);
			if (packRet.length > 0) {
				pack = packRet[0];
			} else {
				return;
			}
			var depPacks = pack.get('dependentPackage')?pack.get('dependentPackage'):[];
			var packTobeCopied = [];
			depPacks.each(function (depPack) {
				if (depPack.get('type') === "vdml_CapabilityLibrary" || depPack.get('type') === "beeppackage_CodeContainer"){
					return;
				}
				var depPackId = depPack.get('id');
				var depPackDependencies = depPack.get('dependentPackage');
				var foundReverseDep = false;
				if(skipDependentPackages && skipDependentPackages.indexOf(depPack) > -1){
					return;
				}
				for(var j=0;j<depPackDependencies.length;j++){
					if(depPackDependencies.at(j).id === self.id){
						foundReverseDep = true;
					}
				}
				if (!foundReverseDep && !pack.get('type') === 'ecomap_EcoMap') {
					return;
				}
				var depPackSufix = depPackId.substr(depPackId.lastIndexOf('@') + 1);
				var nextAlts = copyAlternative.getAlternativesWhereICanBeReffered(true);
				for(var i=0;i<nextAlts.length;i++){
					var copyDepPackId = DataManager.getDataManager().getRepositoryId(nextAlts[i].get('id')) + depPackSufix;
					if(nextAlts[i].get('phaseDesignPart').findWhere({beepReference:copyDepPackId})){
						nextAlts.length = 0;
						return;
					}
				}
				if(!copyAlternative.get('phaseDesignPart').findWhere({beepReference:depPackId})){
					return;
				}
				nextAlts.length = 0;
				packTobeCopied.push(depPack);
				//var packSuffix = depPack.id.substr(depPack.id.lastIndexOf('@')+1);
				var alreadyAdded = false;
				for(var i=0;i<copyPackages.length;i++){
					var copyPack = copyPackages.at(i);
					var copyPackSuffix = copyPack.id.substr(copyPack.id.lastIndexOf('@')+1);
					if(copyPackSuffix === depPackSufix){
						alreadyAdded = true;
						break;
					}
				}
				if (!alreadyAdded) {
					packs.push(depPack);
					copyPackages.add(depPack);
				}
			});
			while(packs.length > 0){
				//if(!copyPackages.get(depPack)){
					self.addDependentPackage(packs,copyPackages,copyAlternative,skipDependentPackages);
				//}
			};
		}
		checkBmModels(model,callback){
			var bmsList = [];
			if(model.get('type') === "vdml_ValueProposition"){
				model.get('provider').getBusinessModelsInvolved(function(bms){
					for(var i=0;i<bms.length;i++){
						var isVpModelExist = _.filter(bms.models[i].get('bmValueProposition').models, function (mod) { if (mod.get('valueProposition') == model) return true});
						if(!isVpModelExist){
							isvalueStreamExist = _.filter(bms.models[i].get('bmValueFormula').models, function (mod) { if (mod.get('valueFormula') == model) return true});
						}
						if(isVpModelExist.length > 0){
							bmsList.push(bms.models[i] );
						}
					}
					callback(bmsList);
				});
			}else if(model.get('type') == "vdml_Activity" || model.get('type') == "vdml_BusinessItem" || model.get('type') == "vdml_CapabilityOffer" || model.get('type') == "vdml_Party"){
				model.getBusinessModelsInvolved(function(bms){
					for(var i=0;i<bms.length;i++){
						bmsList.push(bms[i]);
					}
					callback(bmsList);
				});
			}else if(model.get('type') == "vdml_OrgUnit" || model.get('type') == "vdml_Community" || model.get('type') == "vdml_Actor"){
				model.getBusinessModelsInvolved(function(bms){
					_.each(bms,function(bm){
						if(bm.get('bmCustomer').findWhere({customer:model})){
							bmsList.push(bm);
						}else if(bm.get('bmNetworkPartner').findWhere({networkPartner:model})){
							bmsList.push(bm);
						}else if(bm.get('business') == model){
							bmsList.push(bm);
						}
					}) 
					callback(bmsList);
				});
			}else if(model.get('type') == "vdml_BusinessNetwork"){
				model.getBusinessModelsInvolved(function(bms){
					for(var i=0;i<bms.length;i++){
					bmsList.push(bms[i]);
					}
					callback(bmsList);
				})
			}else if(model.get('type') == "vdml_ValueAdd" || model.get('type') == "vdml_ValuePropositionComponent"){
				callback();
			}
		}
		
		
		copyToAlternative(currentAltenative,copyAlternative,callback,primaryChange,skipDependentPackages){
			var self = this;
			var copyPackages = new Backbone.Collection();
			var nextAlts = copyAlternative.getAlternativesWhereICanBeReffered();	
			copyPackages.add(this);
			var packagesToget = [this];
			self.addDependentPackage(packagesToget, copyPackages, copyAlternative, skipDependentPackages);
			var delScenarios = [];
			_.each(copyPackages.models,function(cpyPack){
				var packageScenarios = cpyPack.get('scenario');
				if (packageScenarios) {
					var nextScenarios = [];
					_.each(nextAlts,function(alt){
						nextScenarios.push(alt.getMainScenario(cpyPack));
					});	
					packageScenarios.each(function (packScenario) {
						if (nextScenarios.indexOf(packScenario) < 0) {
							delScenarios.push(packScenario);
						}
					});
				}
			})
			var copiedPackages = {};
			asyncsimple.each(copyPackages.models,function(pack,handledPackage){
				var sourceAltId = DataManager.getDataManager().getRepositoryId(pack.get('id'));
				
				var getAlaternativeAndCopy = function(){
					var copiedPackage = pack.copyToAlternativeSinglePackage(sourceAlt,copyAlternative,copyPackages,delScenarios);
					copiedPackages[pack.get('id')] = copiedPackage;
					handledPackage();
				}
				var sourceAlt = DataManager.getDataManager().getAlternativeSync(sourceAltId);
				if(sourceAlt){
					getAlaternativeAndCopy();
				}else{
					DataManager.getDataManager().getAlternative(sourceAltId,function(sourceAlt){
						getAlaternativeAndCopy();
					});
				}
			},function(){
				async.eachSeries(copyPackages.models,function(pack,updatedReferences){
					var copiedPackage = copiedPackages[pack.get('id')];
					var sourceAltId = DataManager.getDataManager().getRepositoryId(pack.get('id'));
					var sourceAlt = DataManager.getDataManager().getAlternativeSync(sourceAltId);
					if(sourceAlt){
						pack.updateReferencesOfSinglePackage(copiedPackage,pack,sourceAlt,copyAlternative,copyPackages,primaryChange,delScenarios,updatedReferences);	
					}else{
						DataManager.getDataManager().getAlternative(sourceAltId,function(sourceAlt){
							pack.updateReferencesOfSinglePackage(copiedPackage,pack,sourceAlt,copyAlternative,copyPackages,primaryChange,delScenarios,updatedReferences);	
						});
					}				
				},function(){
					if(callback){
						callback(copiedPackages[self.get('id')]);
					}			
				});			
			});
		};
		copyToAlternativeSinglePackage(currentAltenative,copyAlternative,copyPackages,delScenarios){
			var self = this;

			//var currentMainScenario = currentAltenative.getMainScenario(self);
			//var currentObs = currentMainScenario.get('contextObservation');
			var copyPckStr = JSON.stringify(self);
			var re = new RegExp(currentAltenative.get('id'), 'g');
			var rootObjType = Backbone.Relational.store.getObjectByName('cmof.EObject');
			function replacer(match,offset,totalStr){
				var repVal = copyAlternative.id;
				var idReplaced = totalStr.substr(offset, totalStr.indexOf('"', offset) - offset);
				if (idReplaced[idReplaced.length - 1] === "\\") {
					idReplaced = idReplaced.substr(0, idReplaced.length - 1);
				}
				var reference = rootObjType.find({id:idReplaced});
				if(reference){
					var refParent = reference.getNestedParent();
					if( refParent.id !== self.id){
							if(_.indexOf(copyPackages.models,refParent) < 0){
								return DataManager.getDataManager().getRepositoryId(idReplaced);
							}
					}
				}else{
					console.log('skipped id:' + idReplaced);
				}
				return repVal;
			}
			copyPckStr = copyPckStr.replace(re,replacer);
			var copyPckjson = JSON.parse(copyPckStr);
			copyPckStr = null;
			_.each(delScenarios,function(delScenario){
				var delObs = delScenario.get('contextObservation');
				if(delObs){
					delObs.each(function(delObs){
						var delObsId = delObs.get('id');
						var delObsIdSuffix = delObsId.substr(delObsId.lastIndexOf('@')+1);
						var delObsIdInCopy = copyAlternative.get('id') + delObsIdSuffix;
						var metrics = copyPckjson.metricsModel;
						_.each(metrics,function(metric){
							var observations = metric.observations;
							for(var i=observations.length-1;i>=0;i--){
								if(observations[i].id === delObsIdInCopy){
									observations.splice(i,1);
								}				
							}
								
						});
					});
				}			
			});
			var currentNextAlts = currentAltenative.getAlternativesWhereICanBeReffered();
			for(var i=currentNextAlts.length-1;i>=0;i--){
				if(currentNextAlts[i] === currentAltenative || currentNextAlts[i] === copyAlternative){
					currentNextAlts.splice(i,1);
				}
			}
			var metrics = copyPckjson.metricsModel;
			_.each(metrics,function(metric){
				var lib = metric.libraries;
				if(lib) {
					for(var i=lib.length-1;i>=0;i--){
						var measureElements = lib[i].measureElements;
						for(var j=measureElements.length-1;j>=0;j--){
							var baseMeasureFrom = measureElements[j].baseMeasureFrom;
							if(baseMeasureFrom) {
								for(var k=baseMeasureFrom.length-1;k>=0;k--){
									var baseMeasureFromId = DataManager.getDataManager().getRepositoryId(baseMeasureFrom[k]);
									var altFound = _.filter(currentNextAlts,function(obj){return obj.get('id') === baseMeasureFromId;});
									if(altFound && altFound.length>0){
										baseMeasureFrom.splice(k,1);
									}
								}
							}
						}
					}
				}
			});
			var copyPck = new self.constructor(copyPckjson,{silent:true});
			copyPck.set('s',0,{silent:true});
			DataManager.getDataManager().addNewObjectToChangedObjects(copyPck,true);
			//copyPck.set('s',1);
			copyPckjson = null;
			//copyPck.set('copySource',self.get('id'));
			//copyPck.set('copyTimeStamp',(new Date()).getTime());
			copyPck.set({s:1, copySource: self.get('id'), copyTimeStamp: (new Date()).getTime()});
			DataManager.getDataManager().getWorkspaceDataWithId(currentAltenative.get('id'),function(wsData){
				var lastChangeSet = wsData.get('lastChangeSet');
				var lastChangeSetId = lastChangeSet;
				if(lastChangeSet instanceof Backbone.Model) {
					lastChangeSetId = lastChangeSet.get('id');
				}
				copyPck.set('copyChangeSet',lastChangeSetId);
			});
			return copyPck;
		}
		swapProperty(ref, sourceProp, targetProp){
			var temp = ref[sourceProp];
			ref[sourceProp]  = ref[targetProp];
			ref[targetProp] = temp;
		}
		swapSubjectObject(ref){
			var self = this;
			self.swapProperty(ref,'id','object');
			self.swapProperty(ref,'idPath','objectIDPath');
			self.swapProperty(ref,'namePath','objectNamePath');
			self.swapProperty(ref,'parent','objectParent');
			self.swapProperty(ref,'parentName','objectParentName');
			self.swapProperty(ref,'type','objectType');
			self.swapProperty(ref,'parentType','objectParentType');
			self.swapProperty(ref,'version','objectParentVersion');
			ref.oppPredicate = ref.predicate;
			ref.predicate= null;
		}
		checkRestoreRelations(ref,refModel,model,newRefModel,newModel,nextAlts,callback){
			var predicate; 
			var rel;
			ref.hasReverseRel = false;
			function useNewModel(predicate){
				var oldModel = refModel.get(predicate);
				if(!newModel || !oldModel || ref.predicate !== 'smm_BaseNMeasurementRelationship-to'){
					return false;
				}
				var oldModelAlt = oldModel.get('observedMeasure').get('observation').getContext().getAlternative(true);
				if(_.indexOf(nextAlts,oldModelAlt) >= 0){
					return true;
				}
				return false;
			}
			
			if(ref.predicate){

				predicate = ref.predicate.substr(ref.predicate.lastIndexOf('-')+1);
				var reverseKey = refModel._relations[predicate].reverseRelation.key; 
				if(reverseKey){
					var relations = refModel.relations;
					for(var i=0;i<relations.length;i++){
						if(relations[i].key === predicate){
							rel = relations[i];
							break;
						}
					}				
					ref.revOccType = refModel._relations[predicate].reverseRelation.type;
					if(rel.type === Backbone.HasOne && ref.revOccType === Backbone.HasOne){
						refModel.set(predicate,useNewModel(predicate) ? newModel :model);
						if(newRefModel){
							newRefModel.set(predicate,newModel,{creatingRevision:true});	
						}
					}
					if(rel.type === Backbone.HasMany && ref.revOccType === Backbone.HasOne){
						if(newRefModel && !(DataManager.getDataManager().getRepositoryId(refModel.get('id')) === window.plansKey && 
							(predicate === 'baseMeasureFrom' || predicate === 'baseNMeasurementRelationship'))){
							refModel.get(predicate).remove(newModel,{creatingRevision:true});		
						}
						if(newRefModel){
							newRefModel.get(predicate).remove(model,{creatingRevision:true});
						}
					}
					if(rel.type === Backbone.HasOne && ref.revOccType === Backbone.HasMany){
						refModel.set(predicate,useNewModel(predicate) ? newModel :model,{creatingRevision:true});
						var def = refModel.get('definition');
						if(useNewModel(predicate) && def && def.get('to')) {
							var defToId = DataManager.getDataManager().getRepositoryId(def.get('to').get('id'));
							if(defToId !== DataManager.getDataManager().getRepositoryId(newModel.get('id'))){
								var measureRelationships = def.get('measure').get('measureRelationships');
								for (var m = 0; m < measureRelationships.length; m++) {
									if (measureRelationships.at(m).get('to') && measureRelationships.at(m).get('to') === newModel.get('observedMeasure').get('measure')) {
										if(measureRelationships.at(m).get('to').get('observedMeasure').length > 1){//measure resued
											var parentMsmtRel = refModel.get('from').get('measurementRelationships');
											var alreadyAssigned = false;
											for(var n = 0; n < parentMsmtRel.length; n++){
												if(parentMsmtRel.at(n) != refModel && parentMsmtRel.at(n).get('definition') === measureRelationships.at(m)){
													alreadyAssigned = true;
													break;
												}
											}
											if(alreadyAssigned){
												continue;
											}
										}
										var rescaledMeasure = measureRelationships.at(m).get('rescaledMeasure');
										var relRescaledMeasure = def.get('rescaledMeasure');
										
										var offset = rescaledMeasure?rescaledMeasure.get('offset'):0;
										var multiplier = rescaledMeasure?rescaledMeasure.get('multiplier'):1;
										var operation = rescaledMeasure?rescaledMeasure.get('operation'):null;
										
										var reloffset = relRescaledMeasure?relRescaledMeasure.get('offset'):0;
										var relmultiplier = relRescaledMeasure?relRescaledMeasure.get('multiplier'):1;
										var reloperation = relRescaledMeasure?relRescaledMeasure.get('operation'):null;
										
										if(offset === reloffset && multiplier === relmultiplier && operation === reloperation){
											refModel.set('definition', measureRelationships.at(m));
											if (DataManager.getDataManager().getRepositoryId(refModel.get('from').id) === window.plansKey) {
												var exp = refModel.get('from').get('expression');
												if(exp) {
													var re = new RegExp(model.id, 'g');
													var newExp = exp.replace(re, newModel.get('id'));
													refModel.get('from').set('expression', newExp);
												}
											}
											break;
										}
									}
								}
							}
						}
						if(newRefModel){
							newRefModel.set(predicate,newModel,{creatingRevision:true});
						}
					}
				}
				if(newModel && DataManager.getDataManager().getRepositoryId(refModel.get('id')) === window.plansKey && 
						(predicate === 'aggregatedFrom' || predicate === 'aggregatedTo')){
					refModel.get(predicate).add(newModel,{creatingRevision:true});	
				}				
			}else{
				reverseKey = ref.oppPredicate.substr(ref.oppPredicate.lastIndexOf('-')+1);
				predicate = model._relations[reverseKey].reverseRelation.key; 
				if(predicate){
					var relations = refModel.relations;
					for(var i=0;i<relations.length;i++){
						if(relations[i].key === predicate){
							rel = relations[i];
							break;
						}
					}
					ref.revOccType = refModel._relations[predicate].reverseRelation.type;
					if(rel.type === Backbone.HasOne && ref.revOccType === Backbone.HasOne){
						refModel.set(predicate,useNewModel(predicate) ? newModel :model);
						if(newRefModel){
							newRefModel.set(predicate,newModel,{creatingRevision:true});	
						}
					}
					if(rel.type === Backbone.HasMany && ref.revOccType === Backbone.HasOne){
						if(newRefModel && !(DataManager.getDataManager().getRepositoryId(refModel.get('id')) === window.plansKey && 
							(predicate === 'baseMeasureFrom' || predicate === 'baseNMeasurementRelationship'))){
								refModel.get(predicate).remove(newModel,{creatingRevision:true});	
						}/*else{
							if(newModel){
								newModel.get('measurementRelationshipsOwner').get('baseMeasurementTo').add(newModel);	
							}
						}*/
						if(newRefModel){
							newRefModel.get(predicate).remove(model,{creatingRevision:true});
						}
					}
					if(rel.type === Backbone.HasOne && ref.revOccType === Backbone.HasMany){
						refModel.set(predicate,useNewModel(predicate) ? newModel :model);
						if(newRefModel){
							newRefModel.set(predicate,newModel,{creatingRevision:true});
						}
					}
				}	
				if(newModel && DataManager.getDataManager().getRepositoryId(refModel.get('id')) === window.plansKey && 
						reverseKey === 'baseMeasureFrom'){// && !refModel.get('usePreviousAlternative') can be added here.
					var collectiveMeasure = refModel.get('from');
					var baseRelation = collectiveMeasure.createBaseMeasureRelationship(newModel);
					var rescaledMeasure = refModel.get('rescaledMeasure');
					if(rescaledMeasure){
						baseRelation.createRescaledMeasure(rescaledMeasure.get('offset'),rescaledMeasure.get('multiplier'),rescaledMeasure.get('operation'));
					}
				}	
				if(newModel && DataManager.getDataManager().getRepositoryId(refModel.get('id')) === window.plansKey && 
						reverseKey === 'aggregatedFrom'){
					refModel.get('aggregatedTo').add(newModel,{creatingRevision:true});
				}
			}
			if(callback){
				callback();
			}
		}
		updateReferencesOfSinglePackage(copyPck,oldPackage,currentAltenative,copyAlternative,copyPackages,primaryChange,delScenarios,callback){
			var self = this;
			
			var copyAltId = copyAlternative.get('id');
			var copyMainScenario = copyAlternative.getMainScenario(self);
			var copyMainScenarioPrefix;
			var copyNewMainScenario;
			if (copyMainScenario) {
				copyMainScenarioPrefix = copyMainScenario.id.substr(copyMainScenario.id.lastIndexOf('@')+ 1);
				copyNewMainScenario = copyAltId + copyMainScenarioPrefix;
			}
			var altsWithRefs = self.getAlternativesWithReferencesToPackage(currentAltenative,primaryChange);
			altsWithRefs.unshift(DataManager.getDataManager().get('workspacesData').get(window.plansKey).get('workspace'));
			altsWithRefs.unshift(currentAltenative);
			var nextAlts = copyAlternative.getAlternativesWhereICanBeReffered();
			//nextAlts.splice(nextAlts.indexOf(currentAltenative), 1);
			function canUpdate(altId, newModelId) {
				var newModelIndex;
				if (newModelId) {
					var newModelRep = DataManager.getDataManager().getRepositoryId(newModelId);
					for (var j = 0; j < nextAlts.length; j++) {
						if (nextAlts[j].get('id') === newModelRep) {
							newModelIndex = j;
							break;
						}
					}
				}
				for(var i=0;i<nextAlts.length;i++){
					if (nextAlts[i].get('id') === altId) {
						if (!newModelIndex || i >= newModelIndex) {
							return true;
						}
					}
				}
				return false;	    	
			}
			DataManager.getDataManager().getWorkspaceDataWithId(copyAltId,function(wsData){
				var copyWorkspace = wsData.get('workspace');
				var copyAltVDMStore  = wsData.get('vdmStore');
				copyPck.lawnchair = copyAltVDMStore;
				new PackageReference({beepReference:copyPck,name:copyPck.get('name'),description:copyPck.get('description'),version:copyPck.get('version'),beepType:copyPck.get('type'),label:'Base',packageReferenceOwner:copyWorkspace});
			});
			
			var packageRef = copyAlternative.get('phaseDesignPart').findWhere({beepReference:self.id});
			if(packageRef){
				packageRef.set('beepReference',copyPck.get('id'));
			}else{
				var PlanPackageReference = Backbone.Relational.store.getObjectByName("transformation.PackageReference");
				new PlanPackageReference({beepReference:copyPck.get('id'),name:copyPck.get('name'),description:copyPck.get('description'),version:copyPck.get('version'),beepType:copyPck.get('type'),label:'Base',phaseDesignPartOwner:copyAlternative});
			}
			
			var changeScenarioAlts = copyAlternative.getAlternativesWhereICanBeReffered();
			var copyAltVdmStore = DataManager.getDataManager().getVDMStore(copyAltId);
			var refRdfModels = [];
			_.each(altsWithRefs.models,function(alternative){
				var alternativeId = alternative.get('id');
				var changePackId = false;
				for(var j=0;j<changeScenarioAlts.length;j++){
					if(changeScenarioAlts[j] === alternative){
						changePackId = true;
						break;
					}
				}
				if(alternativeId !== currentAltenative.get('id') && changePackId){
					var packageRef = alternative.get('phaseDesignPart').findWhere({beepReference:self.id});
					if(packageRef){
						packageRef.set('beepReference',copyPck.get('id'));
					}
					var copyPackScenarios = copyPck.get('scenario');
					if (copyPackScenarios) {
						copyPackScenarios.each(function (scenario) {
							var scenarioId = scenario.get('id');
							var repIndId = scenarioId.substr(scenarioId.lastIndexOf('@') + 1);
							var copyAlternativeDesignScenarios = alternative.get('designScenario');
							copyAlternativeDesignScenarios.each(function (designScenario) {
								var dsId = designScenario.get('scenario');
								if (dsId.indexOf(repIndId) > 0) {
									designScenario.set('scenario', copyAltId + repIndId);
								}
							});
						});
					}
				}
				refRdfModels.push(DataManager.getDataManager().getRDFModel(alternativeId));
			});
			
			var plansRDFModel  = DataManager.getDataManager().getRDFModel(window.plansKey);

			plansRDFModel.getReferenceToAlternative(currentAltenative.get('id'),function(references){
				refRdfModels.length = 0;
				var withMeasurement= _.filter(references,function(obj){return obj.type.indexOf("Measurement")>=0;});
				var withOutMeasurement= _.filter(references,function(obj){return obj.type.indexOf("Measurement")<0;});
				asyncsimple.each(withOutMeasurement,function(ref,handledwithOutMeasurementReference){
						referenceHandler(ref,handledwithOutMeasurementReference);
				},function(){
					asyncsimple.each(withMeasurement,function(ref,handledwithMeasurementReference){
							referenceHandler(ref,handledwithMeasurementReference);
					},function(err){	
						function updatePreviousUse(){
							var brokenRels = Backbone.Relational.store.getCollection(Backbone.Relational.store.getObjectByName('smm.BaseNMeasurementRelationship')).where({to:null});
							_.each(brokenRels,function(brokenRel){
								var relId = brokenRel.get('id');
								var relIdSuffics = relId.substr(relId.lastIndexOf('@')+1);
								var prevRelId = currentAltenative.get('id') + relIdSuffics;
								var prevRelModel = Backbone.Relational.store.getObjectByName('smm.BaseNMeasurementRelationship').find({id:prevRelId});
								if(prevRelModel){
									var targetMest = prevRelModel.get('to');
									if(targetMest){
										var rescaledMeasure = prevRelModel.get('definition').get('rescaledMeasure');
										var offset = rescaledMeasure ? rescaledMeasure.get('offset') : 0;
										var multiplier = rescaledMeasure ? rescaledMeasure.get('multiplier') : 1;
										var operation = rescaledMeasure ? rescaledMeasure.get('operation') : null;
										var targetMeasure = targetMest.get('observedMeasure').get('measure');
										var brokenRelOwner = brokenRel.get('from');
										var baseMeasurements = brokenRelOwner.get('baseMeasurements');
										brokenRel.set('to',targetMest,{creatingRevision:true});
										baseMeasurements.push(targetMest);
										var fromMeasure = brokenRelOwner.get('observedMeasure').get('measure');
										//var fromMeasure = prevRelModel.get('from').get('observedMeasure').get('measure');
										if(fromMeasure && targetMeasure) {
											var targetMeasureId = targetMeasure.get('id');
											var targetMeasureSuffics = targetMeasureId.substr(targetMeasureId.lastIndexOf('@')+1);
											var fromMeasureRelationships = fromMeasure.get('measureRelationships');
											var foundTargetMes = false;
											for(var i=0;i<fromMeasureRelationships.length;i++){
												if(fromMeasureRelationships.at(i).get('usePreviousAlternative')){
													var toRelId = fromMeasureRelationships.at(i).get('to').get('id');
													var toRelIdSuffics = toRelId.substr(toRelId.lastIndexOf('@')+1);
													var fromRescaledMeasure = fromMeasureRelationships.at(i).get('rescaledMeasure');
													var fromOffset = fromRescaledMeasure ? fromRescaledMeasure.get('offset') : 0;
													var fromMultiplier = fromRescaledMeasure ? fromRescaledMeasure.get('multiplier') : 1;
													var fromOperation = fromRescaledMeasure ? fromRescaledMeasure.get('operation') : null;
													if(targetMeasureSuffics === toRelIdSuffics && offset === fromOffset && multiplier === fromMultiplier && operation === fromOperation) {
														fromMeasureRelationships.at(i).set('to',targetMeasure,{creatingRevision:true});
														foundTargetMes = true;
														break;
													}
												}
											}
											if(!foundTargetMes){
												var targetTraint = targetMeasure.get('trait');
												var toTraitSuffix = targetTraint.id.substr(targetTraint.id.lastIndexOf('@')+1);
												for(var i=0;i<fromMeasureRelationships.length;i++){
													if(fromMeasureRelationships.at(i).get('usePreviousAlternative')){
														var toTrait = fromMeasureRelationships.at(i).get('to').get('trait');
														var toTraitSuffix = toTrait.id.substr(toTrait.id.lastIndexOf('@')+1);
														if(toTraitSuffix === toTraitSuffix) {
															fromMeasureRelationships.at(i).set('to',targetMeasure,{creatingRevision:true});
															break;
														}
													}
												}										
											}
										}
										var targetMestPackage = targetMest.getNestedParent();
										var targetMestPackageId = targetMestPackage.get('id');
										var targetMestPackageSuffix = targetMestPackageId.substr(targetMestPackageId.lastIndexOf('@')+1);
										var copyDepPackages = copyPck.get('dependentPackage');
										for(var i=0;i<copyDepPackages.length;i++){
											var copyDepPackagesId = copyDepPackages.at(i).get('id');
											var copydepSuffix = copyDepPackagesId.substr(copyDepPackagesId.lastIndexOf('@')+1);
											if(copydepSuffix === targetMestPackageSuffix){
												copyDepPackages.remove(copyDepPackages.at(i));
												break;
											}
										}
										copyPck.get('dependentPackage').add(targetMestPackage);
									}
								}
							});
						}
						nextAlts.length = 0;
						
						updatePreviousUse();
						
						var objSet = copyAlternative.getOrCreatePhaseObjectiveSet();
						if(objSet){
							objSet.set('localChange',true);
						}
										
						function destroyScenarios() {
							if (!copyMainScenario) {
								if (callback) {
									callback();
								}
								return;
							}
							var copyMainScenarioId = copyMainScenario.get('id');
							var currentScnarioToBeDestroyed = self.get('scenario').findWhere({id:copyMainScenarioId});
							if(currentScnarioToBeDestroyed){
								/*var curObservations = currentScnarioToBeDestroyed.get('contextObservation').models.concat();
								for(var  k=0;k<curObservations.length;k++){
									curObservations[k].destroy();
								}*/
								currentScnarioToBeDestroyed.destroy();
							}	
							_.each(delScenarios,function(delScenario){
								var delScenarioId = delScenario.get('id');
								var delScenarioIdSuffix = delScenarioId.substr(delScenarioId.lastIndexOf('@')+1);
								var delNewScenarioId = copyAlternative.get('id') + delScenarioIdSuffix;
								
								var copyScnarioToBeDestroyed = copyPck.get('scenario').findWhere({id:delNewScenarioId});
								if(copyScnarioToBeDestroyed){
									copyScnarioToBeDestroyed.destroy();
								}						
							});
							
							function removeUnsuedScenarios(alts,scenarioColl){
								for(var i=0;i<alts.length;i++){
									if(alts[i] !== currentAltenative && alts[i] !== copyAlternative) {
										var altDesignScenarios = alts[i].get('designScenario');
										var ownedVDMLPackagesIds = alts[i].getOwnedVDMLPackages();
										if(ownedVDMLPackagesIds.indexOf(alts[i].id + window.utils.getSuffix(self.id)) == -1){
											for(var k=0;k<altDesignScenarios.length;k++){
												var altScenario = altDesignScenarios.at(k).get('scenario');
												var altScenarioSuffix = altScenario.substr(altScenario.lastIndexOf('@')+1);
												for(var j=0;j<scenarioColl.length;j++){
													var packageScenario = scenarioColl.at(j).get('id');
													var packageScenarioSuffix = packageScenario.substr(packageScenario.lastIndexOf('@')+1);
													if(altScenarioSuffix === packageScenarioSuffix) {
														scenarioColl.at(j).destroy();
														break;
													}
												}
											}
										}
									}	
								}
							}
							removeUnsuedScenarios(changeScenarioAlts,self.get('scenario'));
							var copyAltThatIcanReffer = [];
							var copyPhase = copyAlternative.get('phaseAlternativeOwner');
							if(copyPhase.get('master') === copyAlternative || copyPhase.get('primary') === copyAlternative) {
								var prevPhase = copyPhase.get('previousPhase');
								while(prevPhase){
									for(var i=0;i<prevPhase.get('phaseAlternative').length;i++){
										copyAltThatIcanReffer.push(prevPhase.get('phaseAlternative').at(i));
									}
									prevPhase =  prevPhase.get('previousPhase');
								}
								if(copyPhase.get('primary') === copyAlternative && copyPhase.get('master') !== copyAlternative){
									copyAltThatIcanReffer.push(copyPhase.get('master'));
								}
								removeUnsuedScenarios(copyAltThatIcanReffer,copyPck.get('scenario'));
							}
							else {
								var re = new RegExp(currentAltenative.get('id'), 'g');
								var newMainScenarioId = copyMainScenarioId.replace(re,copyAlternative.get('id'));
								var len = copyPck.get('scenario').length;
								while(len--){
									var scenario = copyPck.get('scenario').at(len);
									if(scenario.get('id') !== newMainScenarioId){
										scenario.destroy();
									}
								}
							}
							changeScenarioAlts.length = 0;
							copyAltThatIcanReffer.length = 0;
							self.destoryUnsuedMeasures(copyPck.get('metricsModel'));
							
							if(copyAlternative.get('phaseAlternativeOwner').get('primary') === copyAlternative) {
								self.updateScenarioDataForNextAlts(currentAltenative,copyAlternative,copyPck,callback);
							}else {
								if(callback){
									callback();
								}
							}
						}
						DataManager.getDataManager().getRDFModel(copyAltId).addRDFForModel(copyPck,destroyScenarios,true);					
					
						});				
				});
				function referenceHandler(ref,handledReference){
					
						if(ref.predicate === 'partOfDirectly' || ref.predicate === 'transformation_Phase-phaseAlternative' || ref.predicate === 'transformation_Phase-primary'
							|| ref.predicate === 'transformation_Phase-master' || ref.predicate === 'transformation_Alternative-phaseObjectiveSet' ||
							ref.predicate === 'transformation_Phase-master' || ref.predicate === 'transformation_Phase-master'){
							handledReference();
							return;
						}
						var refRepId = DataManager.getDataManager().getRepositoryId(ref.id);
						if(ref.parent === self.get('id')){	// relation in opposite way
							self.swapSubjectObject(ref);
						}
						if(!ref.parent || ref.objectParent !== self.id){
							handledReference();
							return;
						}
						var vdmStore = DataManager.getDataManager().getVDMStore(DataManager.getDataManager().getRepositoryId(ref.id));
						
						function handleReferenceModel(refModel){
							var objectModel;
							var newObjectId;
							var newObjectParentId;								
							var refModelPackage = refModel.getNestedParent().get('id');
							var newModel;
							var newRefModel;
							
							var objVDMStrore = DataManager.getDataManager().getVDMStore(currentAltenative.get('id'));
							objectModel = DataManager.getDataManager().fetchDocumentFromStoreCache(ref.object,ref.objectType);
							if(objectModel){
								loadNewObject();
							}else{
								DataManager.getDataManager().fetchDocumentFromPackage(ref.objectParent,ref.objectParentType,ref.version,ref.object,ref.objectType,objVDMStrore,{
									success:function(model){
										objectModel = model;
										loadNewObject();
									},
									error:function(){
										loadNewObject();
									}
								});
							}
							
							function loadNewObject(){
								if(DataManager.getDataManager().getRepositoryId(ref.object) !== window.plansKey){
									newObjectId = copyAltId + ref.object.substr(ref.object.lastIndexOf('@') + 1);
									newObjectParentId = copyAltId + ref.objectParent.substr(ref.objectParent.lastIndexOf('@') + 1);
								}else{
									newObjectId = ref.id;
									newObjectParentId = ref.parent;
								}
								newModel = DataManager.getDataManager().fetchDocumentFromStoreCache(newObjectId,ref.objectType);
								if(newModel){
									loadNewRefObject();
								}else{
									DataManager.getDataManager().fetchDocumentFromPackage(newObjectParentId,ref.objectParentType,ref.objectParentVersion,newObjectId,ref.objectType,copyAltVdmStore,{
										success:function(model){
											newModel = model;
											loadNewRefObject()
										},
										error:function(){
											loadNewRefObject()
										}
									});
								}
							}

							function loadNewRefObject(){
								var newRefId;
								var newRefParentId;
								if(DataManager.getDataManager().getRepositoryId(ref.id) !== window.plansKey){
									newRefId = copyAltId + ref.id.substr(ref.id.lastIndexOf('@') + 1);
									newRefParentId = copyAltId + ref.parent.substr(ref.parent.lastIndexOf('@') + 1);

									if (!copyPackages.get(newRefParentId) &&  !(copyPackages.get(ref.objectParent) && copyPackages.get(ref.parent))) {
										handleCopyReferences();
										return;
									}
								}else{
									handleCopyReferences();
									return;
								}	
								var newRefVDMStrore = DataManager.getDataManager().getVDMStore(newRefParentId);
								newRefModel = DataManager.getDataManager().fetchDocumentFromStoreCache(newRefId,ref.type);
								if(newRefModel){
									handleCopyReferences();
								}else{
									DataManager.getDataManager().fetchDocumentFromPackage(newRefParentId,ref.parentType,ref.version,newRefId,ref.type,newRefVDMStrore,{
										success:function(model){
											newRefModel = model;
											handleCopyReferences();
										},
										error:function(){
											handleCopyReferences();
										}
									});
								}
							}
							
							function handleCopyReferences(){
								self.checkRestoreRelations(ref,refModel,objectModel,newRefModel,newModel,nextAlts,handleReference);
							}
							function handleReference() {
								if (newRefModel) {
									refModel = newRefModel;
								}
								/*function getCopyObsMeasure(mc){
									var copyMainScenarioPrefix = copyMainScenario.id.substr(copyMainScenario.id.lastIndexOf('@'));
									var mests = mc.get('measurement');
									for(var i=0;i<mests.models.length;i++){
										var mst = mests.at(i);
										var context = mst.get('observedMeasure').get('observation').getContext();
										var contextSuffix = context.id.substr(context.id.lastIndexOf('@'));
										if(copyMainScenarioPrefix === contextSuffix){
											return mst.get('observedMeasure');
										}
									};
								}*/
								function fetchAndUpdateNewObject(){
									var doUpdate;
									if(!newModel){
										handledReference();	
										return;													
									}										
									if(DataManager.getDataManager().getRepositoryId(ref.id) === window.plansKey){
										doUpdate = true;
										var newModelContextId = newModel.get('observedMeasure').get('observation').getContext().id;
										if(copyNewMainScenario !== newModelContextId){
											handledReference();	
											return;													
										}
									}
									var predicateVal;
									var refModelRepId;
									var predicate;
									var collModel;
									if(ref.predicate){
										predicateVal = ref.predicate.substr(ref.predicate.lastIndexOf('-')+1);
										if(refModel){
											refModelRepId = DataManager.getDataManager().getRepositoryId(refModel.get('id'));
											predicate = refModel.get(predicateVal);	
											collModel = predicate.get(ref.object);
										}
									}else{
										predicateVal = ref.oppPredicate.substr(ref.oppPredicate.lastIndexOf('-')+1);
										refModelRepId = DataManager.getDataManager().getRepositoryId(newModel.get('id'));
										predicate = newModel.get(predicateVal);												
										collModel = predicate.get(ref.id);
									}
									
									var updateReferenceToCopiedPackage;
									var isNextAlt = canUpdate(refModelRepId,newModel.id);
									if(!doUpdate){
										doUpdate = isNextAlt;		
									}

									if(predicate && (predicate instanceof Backbone.Collection)){
										if(collModel){
											var collModelId = DataManager.getDataManager().getRepositoryId(collModel.get('id'));
											if (refModelRepId === copyAltId || collModelId === copyAltId || collModelId === window.plansKey || isNextAlt) {
												var newId = "";
												if (doUpdate) {
													if (ref.predicate) {
														newId = newModel.id;
													} else if (refModel) {
														newId = refModel.id;
													}
												}
												predicate.remove(collModel,{creatingRevision:true, revisionCollectionNewId: newId});
											}
										}
										if(doUpdate){
											if(ref.predicate){
												predicate.add(newModel,{creatingRevision:true});		
											}else{
												if(refModel){
													predicate.add(refModel,{creatingRevision:true});
												}
											}
										}
									}else{
										var predicateId;
										if(predicate){
											predicateId = DataManager.getDataManager().getRepositoryId(predicate.get('id'));
											var predicatePackage = predicate.getNestedParent();
											if((refRepId !== currentAltenative.get('id')  && copyPackages.get(refModelPackage) && predicatePackage === oldPackage.get('id')) || 
												(refRepId === currentAltenative.get('id') && copyPackages.get(ref.objectParent) && refModelPackage === oldPackage.get('id'))){
												updateReferenceToCopiedPackage = true;	
											}															
										}
										
										if(doUpdate){
											if(ref.predicate){
												refModel.set(predicateVal,newModel,{creatingRevision:true});			
											}else{
												newModel.set(predicateVal, refModel);
												if (!(newModel && newRefModel)) {
													objectModel.set(predicateVal, null, { creatingRevision: true });
												}
											}
										}else{//TODO when do we come here
											if(refModelRepId === copyAltId || predicateId === copyAltId){
												if(ref.predicate){
													refModel.set(predicateVal,null,{creatingRevision:true});			
												}else{
													newModel.set(predicateVal,null,{creatingRevision:true});
												}	
											}
										}
									}
									if(updateReferenceToCopiedPackage && predicateVal !== 'dependentPackage'){
										var newSubjectId = copyAltId + ref.id.substr(ref.id.lastIndexOf('@') + 1);
										var newSubjectParentId = copyAltId + ref.parent.substr(ref.parent.lastIndexOf('@') + 1);
										var handleNewSubjectModel = function(newSubjectModel){
											var newPredicate = newSubjectModel.get(predicateVal);
											if(newPredicate && (newPredicate instanceof Backbone.Collection)){
												newPredicate.add(newModel,{creatingRevision:true});
											}else{
												newSubjectModel.set(predicateVal,newModel,{creatingRevision:true});
											}
											handledReference();	
										}
										var newSubjectModel = DataManager.getDataManager().fetchDocumentFromStoreCache(newSubjectId,ref.type);
										if(newSubjectModel){
											handleNewSubjectModel(newSubjectModel);
										}else{
											DataManager.getDataManager().fetchDocumentFromPackage(newSubjectParentId,ref.parentType,ref.version,newSubjectId,ref.type,copyAltVdmStore,{
												success:handleNewSubjectModel,
												error:function(){
													handledReference();
												}
											});
										}
									}else{
										handledReference();	
									}
								}
								if(!ref.objectType){
									handledReference();
								}else{
									if(DataManager.getDataManager().getRepositoryId(ref.id) !== window.plansKey){
										fetchAndUpdateNewObject();
									}else{
										if(ref.objectType === 'smm_DirectMeasurement' ||
											ref.objectType === 'smm_CollectiveMeasurement' ||
											ref.objectType === 'smm_RankingMeasurement' ||
											ref.objectType === 'smm_GradeMeasurement'){
											fetchAndUpdateNewObject();
										}else{
											handledReference();
										}
									}
								}									
							}
						}
						var refModel = DataManager.getDataManager().fetchDocumentFromStoreCache(ref.id,ref.type);
						if(refModel){
							handleReferenceModel(refModel);
						}else{
							DataManager.getDataManager().fetchDocumentFromPackage(ref.parent,ref.parentType,ref.version,ref.id,ref.type,vdmStore,{
								success:handleReferenceModel,
								error:function(error){
									console.log(error);
									handledReference();
								}
							});	    					
						}
				
				}
			},[self.get('id')],refRdfModels);
		};

		updateScenarioDataForNextAlts(currentAltenative,copyAlternative,copyPck,callback){
			var nextAlts = copyAlternative.getAlternativesWhereICanBeReffered();
			var nextScenarios = [];
			var measuresToBeReplaced = [];
			var copyPckSuffix = window.utils.getSuffix(copyPck.id);
			_.each(nextAlts,function(alt){
				if(alt.get('phaseAlternativeOwner') !== copyAlternative.get('phaseAlternativeOwner')){
					var altDesignpart = alt.get('phaseDesignPart');
					for(var i=0;i<altDesignpart.length;i++){
						if(altDesignpart.at(i).get('beepType') === "vdml_ValueDeliveryModel" && window.utils.getSuffix(altDesignpart.at(i).get('beepReference')) === copyPckSuffix){
							var forwardPack = Backbone.Relational.store.getObjectByName('vdml.ValueDeliveryModel').find({ id: altDesignpart.at(i).get('beepReference') });
							var forwardScenario = forwardPack?alt.getMainScenario(forwardPack):null;
							if (forwardScenario){
								nextScenarios.push(forwardScenario);
							}											
							break;
						}
					}
				}
			});						
			_.each(nextScenarios,function(forwardscenario){
				var observations = forwardscenario.get('contextObservation');
				_.each(observations.models,function(observation){
					var observedMeasures = observation.get('observedMeasures');
					_.each(observedMeasures.models,function(observedMeasure){
						var measure = observedMeasure.get('measure');
						if(measure && measure.get('type') === 'smm_CollectiveMeasure'){
							var measureRelationships = measure.get('measureRelationships');
							_.each(measureRelationships.models,function(relationship){
								if(relationship.get('usePreviousAlternative')){ //TODO: check whether relationship.get('to') is from plan value or depependent package
									var msmt = observedMeasure.get('measurements').at(0);
									var msmtRels = msmt.get('measurementRelationships');
									for(var i = 0;i < msmtRels.length; i++){
										if(!msmtRels.at(i).get('definition')){
											msmtRels.at(i).set('definition',relationship);
											break;
										}
									}
									var msmtRel = msmt.get('measurementRelationships').findWhere({'definition':relationship});
									var measureJson = {};
									if(msmtRel && msmtRel.get('to')){
										var newMeasure = msmtRel.get('to').get('observedMeasure').get('measure');
										if(newMeasure){
											measureJson[relationship.get('to').id] = newMeasure;
											msmt.get('baseMeasurements').push(msmtRel.get('to'));
										}
									}
									measuresToBeReplaced.push({measurement:msmt,measures:measureJson});
								}
							});	
						}
					});
				});
			});
			asyncsimple.each(measuresToBeReplaced,function(replaceObj,replacedMeasure){
				replaceObj.measurement.replaceMeasureToHandleChangedPreviousAltMeasure(replaceObj.measures);
				replaceObj.measurement.calculate();
				replacedMeasure();						
			},function(){
				if(callback){
					callback();
				}				
			});
		}

		destoryUnsuedMeasures(metricsModel){
			if(!metricsModel){
				return;
			}
			var unsuedMeasures = [];
			for(var j=0;j<metricsModel.length;j++){
				var libs = metricsModel.at(j).get('libraries');
				for(var k=0;k<libs.length;k++){
					var measureElements = libs.at(k).get('measureElements');
					for(var m=0;m<measureElements.length;m++){
						if(measureElements.at(m).get('observedMeasure') && measureElements.at(m).get('observedMeasure').length < 1) {
							unsuedMeasures.push(measureElements.at(m));
						}
					}
				}
			}
			var unsuedMeasureslen = unsuedMeasures.length;
			while(unsuedMeasureslen--){
				unsuedMeasures[unsuedMeasureslen].destroy();
			}
		}
		
		referPackageToNextPhases(currentAlternative){
			var self = this;
			var altsWithRefs = currentAlternative.getAlternativesWhereICanBeReffered();
			var currentPhase = currentAlternative.get('phaseAlternativeOwner');
			for(var i=0;i<altsWithRefs.length;i++){
				var revisionExists = false;
				if(altsWithRefs[i].get('phaseAlternativeOwner') === currentPhase && altsWithRefs[i].get('phaseAlternativeOwner').get('master') !== currentAlternative){
					continue;
				}
				if(altsWithRefs[i] !== currentAlternative) {
					var designParts = altsWithRefs[i].get('phaseDesignPart'); 
					for(var j=0;j<designParts.length;j++){
						var designbeepId = designParts.at(j).get('beepReference');
						var beepRefSuffix = designbeepId.substr(designbeepId.lastIndexOf('@')+ 1);
						var packageSuffix = self.get('id').substr(self.get('id').lastIndexOf('@')+ 1);
						if(packageSuffix === beepRefSuffix) {
							revisionExists = true;
							break;
						}
					}
					if(!revisionExists) {
						var pprId = DataManager.getDataManager().guidGeneratorByOwner(altsWithRefs[i].get('phaseAlternativeOwner'));
						var PlanPackageReference = Backbone.Relational.store.getObjectByName("transformation.PackageReference");
						var newPacRef = new PlanPackageReference({id:pprId,'beepType':self.get('type'),beepReference:self.get('id'),name:self.get('name'),description:self.get('description'),version:self.get('version'),label:self.get('label'),isMain:true,phaseDesignPartOwner:altsWithRefs[i]});	
						//newPacRef.set('beepType',self.get('type'));
						designParts.add(newPacRef);
						if(self.createScenario){
							//var scenarioPath = self.getNamePath(altsWithRefs[i]);
							var snapshotId = window.utils.getScenarioSnapshotId(altsWithRefs[i],null);
							var scenario = self.createScenario(snapshotId);
							var localChange = altsWithRefs[i].get('phaseObjectiveSet')?altsWithRefs[i].get('phaseObjectiveSet').get('localChange'):false;
							if(localChange){
								scenario.set('localChange',true);
							}
							if(altsWithRefs[i].get('phaseAlternativeOwner').get('previousPhase')){
								var earilerScenario = altsWithRefs[i].get('phaseAlternativeOwner').get('previousPhase').get('primary').getMainScenario(self);
								if(earilerScenario) {
									earilerScenario.copy(scenario);
								}	
							}
							var scenarioProxyId = DataManager.getDataManager().guidGeneratorByOwner(altsWithRefs[i].get('phaseAlternativeOwner'));
                            var ScenarioProxy = Backbone.Relational.store.getObjectByName("transformation.ScenarioProxy");
							new ScenarioProxy({id:scenarioProxyId,name:"Scenario Proxy",scenario:scenario.get('id'),designScenarioOwner:altsWithRefs[i]});
						}
					}
				}
			}
		};
		
		getNamePath(currentAlternative){
			var plan = DataManager.getDataManager().get('currentPlan');
			var path = '';
			if (typeof currentAlternative === 'string') {
				currentAlternative = Backbone.Relational.store.getObjectByName('transformation.Alternative').find({ id: currentAlternative });
			}
			if(!currentAlternative){
				return path;
			}
			if(!plan) {
				plan =  currentAlternative.get('phaseAlternativeOwner').get('phaseOwner');
			}	
			var phase = currentAlternative.get('phaseAlternativeOwner');
			path = plan.get('name') + "_" + phase.get('name') + "_" + currentAlternative.get('name');
			return path;
		};
		
		getDocumentWithNamePath(namePath, type, currentAlt, isValue, pack, callback) {
			var alts = currentAlt.getMasterPrimaryAlternativesOfPackage(currentAlt,pack);
			var rdfModels = [];
			async.each(alts, function (alt, altWSLoaded) {
				DataManager.getDataManager().getModelIdInitializedWSData((typeof alt === 'string') ? alt : alt.id, function (wsData) {
					if (wsData) {
						rdfModels.push(wsData.get('rdfModel'));
					}
					altWSLoaded();
				});
			}, function () {
				alts.length = 0;
				if (rdfModels.length > 0) {
					var altRDFModel = DataManager.getDataManager().getRDFModel(window.plansKey);
					if (altRDFModel) {
						/*if(isValue) {
							rdfModels.push(altRDFModel);
						}*/
						altRDFModel.getDocumentWithNamePath(namePath, type, rdfModels, isValue, function (results) {
							if (results && results[0]) {
								var doc = results[0].doc;
								var suffix = window.utils.getSuffix(doc);
								var typeColl = Backbone.Relational.store.getObjectByName(type.replace('_', '.'));
								if (typeColl) {
									var valueExists = typeColl.find({ id: DataManager.getDataManager().get('viewAlternative') + suffix });
								}
								if (valueExists) {
									results = [];
								}
								callback(results);
							} else {
								callback(results);
							}                        
						});
					} else {
						callback();
					}
				} else {
					callback();
				}

			});		
		};
		
		getAlternativesWithReferencesToPackage(currentAltenative,primaryChange){
			var ret = new Backbone.Collection();
			var phase = currentAltenative.get('phaseAlternativeOwner');
			var currentAlternatives = phase.get('phaseAlternative');
			if(!primaryChange){
				for(var i=0;i<currentAlternatives.length;i++){
					var nextAlt = currentAlternatives.at(i);
					if(nextAlt !== currentAltenative) {
						var packRefs = nextAlt.get('phaseDesignPart');
						for(var j=0;j<packRefs.length;j++){
							var packRef = packRefs.at(j);
							if(packRef.get('beepReference') === this.get('id')){
								ret.add(nextAlt);
							}
						}
					}
				}	
			}
			var phasePrimary = phase.get('primary');
			var master = phase.get('master');
			var isPrimary = (phasePrimary === currentAltenative);
			var isMaster = (master === currentAltenative);
			if((isPrimary || isMaster) || primaryChange){
				var nextPhase = phase.get('nextPhase');
				while(nextPhase){
					var nextAlternatives = nextPhase.get('phaseAlternative');
					for(var i=0;i<nextAlternatives.length;i++){
						var nextAlt = nextAlternatives.at(i);
						var packRefs = nextAlt.get('phaseDesignPart');
						for(var j=0;j<packRefs.length;j++){
							var packRef = packRefs.at(j);
							if(packRef.get('beepReference') === this.get('id')){
								ret.add(nextAlt);
							}
						}
					}
					nextPhase = nextPhase.get('nextPhase');
				}
			}
			return ret;		
		};
		
		getVersion(){
			return Math.round(this.get('version')/1000);
		};
		getRevision(){
			return (this.get('version') - Math.round(this.get('version')/1000) * 1000);
		};
		
		isPersisted(){
			return true;
		};
		destroyPackageReferences(){
			var self = this;
			if(!DataManager.getDataManager().get('suppressLogging')) {
				var altId = DataManager.getDataManager().getRepositoryId(self.id);
				DataManager.getDataManager().getWorkspaceDataWithId(altId,function(wsData){
					if(wsData){
						var packRefs = wsData.get('workspace').get('packageReference').models.concat();
						_.each(packRefs,function(wsPackRef){
							var beepRef = wsPackRef.get('beepReference');
							if(beepRef && beepRef.id === self.id){
								wsPackRef.destroy();
							}
						});
						var alt = Backbone.Relational.store.getObjectByName("transformation.Alternative").find({id:altId});
						var altPackRef =alt?alt.get('phaseDesignPart').findWhere({'beepReference':self.id}):null;
						if(altPackRef){
							console.log('destroyed alt ref');
							altPackRef.destroy();
						}
					}
				});
			}
			
		};
		
		updateScenario(currentAltModel,callback){
			var self = this;
			if(DataManager.getDataManager().getRepositoryId(self.id) === currentAltModel.id){
				callback();
				return;
			}
			var currentPhase = currentAltModel.get('phaseAlternativeOwner');
			var currentScenario = currentAltModel.getMainScenario(self);
			function getPreviousAlternativeForScenario(altModel){
				var currentAlt = altModel;
				var currentPhase = altModel.get('phaseAlternativeOwner');
				var master = currentPhase.get('master');
				if(currentAlt !== master){
					currentAlt = master;
				}else{
					currentPhase = currentPhase.get('previousPhase');
					if(currentPhase){
						currentAlt = currentPhase.get('primary');	
					}
				}
				if(currentAlt === altModel){
					return null;
				}
				return currentAlt;
			};
			if(currentScenario){
				var earlierAlt = getPreviousAlternativeForScenario(currentAltModel);
				if(!earlierAlt){
					callback();
					return;
				}
				DataManager.getDataManager().invokeValueUpdates(function(){
					DataManager.getDataManager().acquireSaveLock(function () {
						var earilerScenario = earlierAlt.getMainScenario(self);
						if (earilerScenario.copy) {
							var measurementsCopied = earilerScenario.copy(currentScenario, [], true);
						} else {
							var measurementsCopied = earilerScenario.copyData(currentScenario, false, null, null, measurementsCopied, true);
						}
						currentPhase.get('phaseOwner').copyMeasurements(measurementsCopied, function () {
							DataManager.getDataManager().releaseSaveLock();
							callback();
						});
					});
				});
			}else {
				callback();
			}
		};
		
		updateScenarioContextBased(self,callback){
			if(window.checkContextForRevision()){
				function fetchAlternativeModel(tempAlt){
					var phase = DataManager.getDataManager().get('currentPlan').get('phase');
					for(var j=0;j<phase.length;j++){
						var alts = phase.at(j).get('phaseAlternative');
						var viewAlternativeModel = alts.findWhere({'id':tempAlt});
						if(viewAlternativeModel){
							return viewAlternativeModel;
						}
					}
				}
				
				var beepPackage = self.model.getNestedParent();
				var currentAltModel = fetchAlternativeModel(DataManager.getDataManager().get('viewAlternative'));
				if(!currentAltModel){
					callback();
					return;
				}
				beepPackage.updateScenario(currentAltModel,callback);
			}else {
				callback();
			}
		}
	
//#endCustomMethods	
	//path.BeepPackageMixin = BeepPackageMixin;
	//return BeepPackageMixin;
}
