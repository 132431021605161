import * as jQuery from 'jquery'
import * as _ from 'underscore'
import * as  Backbone from 'backbone'
import * as async from 'async'
import {BeepPackage} from '../beeppackage/BeepPackage'
import {BeepPackageMixin} from '../beeppackage/BeepPackageMixin'
import {DataManager} from '../../../com/vbee/data/DataManager'
import {EcoMapDiagram} from '../vdml/EcoMapDiagram'
import * as bootbox from '../../../../libs/bootbox/bootbox'
//define(["require", "jquery", "underscore", "backbone","async", "Lawnchair", "backbone-lawnchair", "appcommon/com/vbee/data/DataManager", "app/global", "appbo/beeppackage/BeepPackage", "appbo/beeppackage/BeepPackageMixin", "appbo/vdml/EcoMapDiagram"],
//function (require, jQuery, _, Backbone,async, Lawnchair, backboneLawnchair, DataManager, global, BeepPackage, BeepPackageMixin, EcoMapDiagram
//){
	
var path = DataManager.getDataManager().buildAppNsPath("ecomap",global.version);
export class EcoMapMixin{

	defaults(){
		var ret = {
			type: "ecomap_EcoMap",
			version: DataManager.getDataManager().get('currentVDMVersion')
		}
		return jQuery.extend(BeepPackage.prototype.defaults.apply(this),ret);
	}
	static getMixinRelations(){
		return _.union([
		{
		    type: Backbone.HasMany,
		    containingClass: "ecomap_EcoMap",
		    key: "diagrams",
		    relatedModel: "vdml.CollaborationDiagram",
		    reverseRelation: {
		        key: "ecoMap",
		        type: Backbone.HasOne,
		        includeInJSON: "id"
		    }
		},
		]);
	};
	static getCumulativeMixinRelations(){
		if (!EcoMapMixin.cummulativeRelations) {
            EcoMapMixin.cummulativeRelations = _.union(EcoMapMixin.getMixinRelations()
                , BeepPackageMixin.getCumulativeMixinRelations()
            );
        }
		return EcoMapMixin.cummulativeRelations.slice();
	};
	static getSuperTypes(){
		return [
			"beeppackage_BeepPackage"
		];
	}
	static getProperties(){
		return [
			{name : "version",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
            { name: "name", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "description", type: "EString", defaultValue: "null", containingClass: "cmof_EObject" },
            { name: "progress", type: "double", defaultValue: "0", containingClass: "cmof_EObject" },
			{name : "label",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "parentLabel",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "syncedChangeLog",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" },
			{name : "synchedChangeSet",type : "EString",defaultValue : "null",containingClass : "beeppackage_BeepPackage" }
		]
    }
    calculateProgress () {

    }
	getParent(){
		var container;
		return BeepPackage.prototype.getParent.apply(this, arguments);
	}
	getPackagePath(path){
		if(!path){
			path = [];
		}
		return BeepPackage.prototype.getPackagePath.apply(this, arguments);
	}
	static getDialogViewProperties(type){
		if(type && type==="ecomap_EcoMap"){
			return{
				templatePath : "views/ecoMap/views/designer/EcoMapPackageTemplate.html",
			    templateName : "EcoMapPackageTemplate",
			    viewTypeStr : "appviews/ecoMap/views/designer/EcoMapPackageViewModel",
			    tabId : "EcoMapPackageView",
			    tabName: "EcoMapPackage"
			}
		}
		else if(type==="explorerModelJson"){
			return{
				templatePath : "views/beeppackage/views/properties/explorerModelJsonTemplate.html",
			    templateName : "explorerModelJsonTemplate",
			    viewTypeStr : "appviews/beeppackage/views/properties/explorerModelJsonViewModel",
			     tabId: "explorerModelJsonView",
			    tabName: "explorerModelJson"
			}
		}		
	}
	getViewProperties(type){
		jQuery("#summernotecss").attr("href", "js/libs/summernote/dist/summernote.css");
		return {
			templatePath : "views/ecomap/views/summernote/SummernoteReportTemplate.html",
			templateName : "SummernoteReportTemplate",
			viewTypeStr : "appviews/ecomap/views/summernote/SummernoteReportViewModel",
			tabId : "SummernoteReportView",
			tabName: "SummernoteReport"
		}
	}
	//#startCustomMethods
	handlePackageNameChange(model,newName){
		if(Backbone.skipPersistance){
			return;
		}		
		var self = this;
		var alternativeId = DataManager.getDataManager().getRepositoryId(self.get('id'));
		if(!(alternativeId.indexOf("Common") > -1)){
		    DataManager.getDataManager().getAlternative(alternativeId,function(alternative){
		    	var phase = alternative.get('phaseAlternativeOwner');
		    	if(phase.get('master') === alternative || phase.get('primary') === alternative) {
			    	var plan = phase.get('phaseOwner');
					for(var j=0;j<plan.get('phase').length;j++){
						var alts = plan.get('phase').at(j).get('phaseAlternative');
						for(var k=0;k<alts.length;k++){
							var designParts = alts.at(k).get('phaseDesignPart');
							var packRef = designParts.findWhere({'beepReference':self.get('id')});
							if(packRef){
								packRef.set('name',newName);
								packRef.set('description',newName);
								break;
							}
						}	
					}
				}
				else {
					var designParts = alternative.get('phaseDesignPart');
					var packRef = designParts.findWhere({'beepReference':self.get('id')});
					if(packRef){
						packRef.set('name',newName);
						packRef.set('description',newName);
					}
				}
		    });	
	    }
	};
	checkBmModels(businessObject, parentId,callback){
		    var bmsList = [];var model,vsModel;
			var businessObjectId = businessObject.mid;
			if(businessObject.$type === "vdml:ValueProposition" || businessObject.$type === "vdml:ValueStream"){
				model = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({id:businessObjectId});
				if(!model && businessObject.$type === "vdml:ValueStream"){
					vsModel = Backbone.Relational.store.getObjectByName('vdml.ValueStream').find({id:businessObjectId});
					model = vsModel ? vsModel.get('persuedProposition') : "";
				}
				if(model){
					model.get('provider').getBusinessModelsInvolved(function(bms){
						for(var i=0;i<bms.length;i++){
				   		   if(businessObject.$type === "vdml:ValueStream"){
						        var isvalueStreamExist = _.filter(bms.models[i].get('bmValueStream').models, function (model) { if (model == vsModel) return true});
								if(isvalueStreamExist){
									bmsList.push(bms.models[i]);
								}
						   }else{
								var isVpModelExist;
								if(model.get('isValueFormula')){
							 		isVpModelExist = _.filter(bms.models[i].get('bmValueFormula').models, function (mod) { if (mod.get('valueFormula') == model) return true});
								}else {
									isVpModelExist = _.filter(bms.models[i].get('bmValueProposition').models, function (mod) { if (mod.get('valueProposition') == model) return true});
								}
								if(isVpModelExist.length > 0){
								    bmsList.push(bms.models[i] );
								}
						   }
						}
						callback(bmsList);
					});
				}
			}else if(businessObject.$type === "vdml:Role"){
				model = Backbone.Relational.store.getObjectByName('vdml.Party').find({id:businessObjectId});
			    if(model){
					model.getBusinessModelsInvolved(function(bms){
						for(var i=0;i<bms.length;i++){
				   		   bmsList.push(bms.models[i] );
						}
						callback(bmsList);
					});
				}
			}else if(businessObject.$type === "vdml:MarketSegment"){
				model = Backbone.Relational.store.getObjectByName('vdml.Community').find({id:businessObjectId});
			    if(model){
					model.getBusinessModelsInvolved(function(bms){
						_.each(bms,function(bm){
							if(bm.get('bmCustomer').findWhere({customer:model})){
								bmsList.push(bm);
							}else if(bm.get('bmNetworkPartner').findWhere({networkPartner:model})){
								bmsList.push(bm);
							}else if(bm.get('business') == model){
								bmsList.push(bm);
							}
						})
						callback(bmsList);
					});
				}
			}else if(businessObject.$type === "vdml:Enterprise"){
				model = Backbone.Relational.store.getObjectByName('vdml.OrgUnit').find({id:businessObjectId});
			    if(model){
					model.getBusinessModelsInvolved(function(bms){
						_.each(bms,function(bm){
							if(bm.get('bmCustomer').findWhere({customer:model})){
								bmsList.push(bm);
							}else if(bm.get('bmNetworkPartner').findWhere({networkPartner:model})){
								bmsList.push(bm);
							}else if(bm.get('business') == model){
								bmsList.push(bm);
							}
						}) 
						callback(bmsList);
					});
				}
			}else if(businessObject.$type === "vdml:Individual"){
				model = Backbone.Relational.store.getObjectByName('vdml.Actor').find({id:businessObjectId});
			    if(model){
					model.getBusinessModelsInvolved(function(bms){
						_.each(bms,function(bm){
							if(bm.get('bmCustomer').findWhere({customer:model})){
								bmsList.push(bm);
							}else if(bm.get('bmNetworkPartner').findWhere({networkPartner:model})){
								bmsList.push(bm);
							}else if(bm.get('business') == model){
								bmsList.push(bm);
							}
						}) 
						callback(bmsList);
					});
				}
			}else if(businessObject.$type === "vdml:Activity"){
				model = Backbone.Relational.store.getObjectByName('vdml.Activity').find({id:businessObjectId});
				if(model){
					model.getBusinessModelsInvolved(function(bms){
						for(var i=0;i<bms.length;i++){
				   			if(parentId){
								var valueStreamModel = Backbone.Relational.store.getObjectByName('vdml.ValueStream').find({id:parentId});
								var checkModel = _.filter(bms[i].get('bmValueStream').models, function (model) { if (model == valueStreamModel) return true})
								if(checkModel){
									bmsList.push(bms[i]);
								}
							}else{
							   bmsList.push(bms[i]);
							}
						}
						callback(bmsList);
					});
				}
			}else if(businessObject.$type === 'vdml:Competency'){
				model = Backbone.Relational.store.getObjectByName('vdml.Competence').find({id:businessObjectId});
				if(model){
					model.getBusinessModelsInvolved(function(bms){
						for(var i=0;i<bms.length;i++){
				   			   if(parentId){
							   	   var activityModel = Backbone.Relational.store.getObjectByName('vdml.Activity').find({id:parentId});
								   var checkCompetenceExist = _.filter(bms[i].get('bmActivity').models, function(model) {if(model == activityModel) return true});
								   if(checkCompetenceExist){
								   	   bmsList.push(bms[i]);
								   }
							   }else{
							     bmsList.push(bms[i] );
							   }
						}
						callback(bmsList);
					});
				}
			}else if(businessObject.$type === 'vdml:Value'){
				//callback('');
				var source = "";
				var valParent;
				model = Backbone.Relational.store.getObjectByName('vdml.ValueAdd')?Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({id:businessObjectId}):"";
				if(model){
					source = 'Activity';
					valParent = model.getParent().getParent();
				}
				if(!model){
					model = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({id:businessObjectId});
					valParent = model.getParent();
					if (model.get('componentOwner').get('isValueFormula')) {
						source = 'MyProposition';
					} else {
						source ='ValueProposition';
					}
				}
				var valAltId = model ? window.utils.getPrefix(model.id) : null;
				var businessModels = [];
				if(model && valAltId != window.plansKey){
					var self=this;
					DataManager.getDataManager().getAllDocumentsCollectionOfType(self, 'vdml_BusinessModel', function (refs) {
						var count = refs.length;
						function getBMInstance() {
							count--;
							if (count >= 0 && refs.at(count).get('parent') && valAltId == window.utils.getPrefix(refs.at(count).id)) {
								var ref = refs.at(count);
								var parentId = ref.get('parent');
								var repId = DataManager.getDataManager().getRepositoryId(parentId);
								var vdmStore = DataManager.getDataManager().getVDMStore(repId);
								DataManager.getDataManager().fetchDocumentFromPackage(parentId, "appbo/vdml/ValueDeliveryModel", ref.get('version'), ref.get('id'), "appbo/vdml/BusinessModel", vdmStore, {
									success: function (bmModel) {
										var valFound = false;
										if(source == "Activity"){
											valFound = bmModel.get('bmActivity').findWhere({'activity': valParent});
										}else if(source == "ValueProposition"){
											valFound = bmModel.get('bmValueProposition').findWhere({'valueProposition': valParent});
										}else{
											valFound = bmModel.get('bmValueFormula').findWhere({'valueFormula': valParent});
										}
										if (valFound) {
											businessModels.push(bmModel);
										}
										getBMInstance();
									},
									error: function (error) {
										console.log(error);
										getBMInstance();
									}
								});
							} else {
								if (count >= 0) {
									getBMInstance();
								} else {
									callback(businessModels);
								}
							}
						}
						getBMInstance();
					}, null, null, true, null, false);
				} else {
					callback(businessModels);
				}
			}
    }

	static deleteTargetElement(businessObject,selectedBms,callback){
		var model;
		var businessObjectId = businessObject.mid;
		function deleteParticipant(partModel,callback){
			var obDel = [];
			var roleArray = [];
			for(var i=0;i<selectedBms.length;i++){
				var bm = selectedBms[i];
				if(bm.get('business') == partModel){
					_.each(bm.get('participantNetwork').models,function(pn){
						pn.removeCollaborationToScenarioContexts(bm);
						obDel.push(pn);
					});
				}else {
					var bmCustomer = selectedBms[i].get('bmCustomer').findWhere({'customer':partModel});
					var bmNetPart = selectedBms[i].get('bmNetworkPartner').findWhere({'networkPartner':partModel});
					if(bmNetPart) {
						_.each(bmNetPart.get('networkPartnerRole').models,function(role){
							_.each(role.get('roleAssignment').models,function(assignment){
								if(assignment.get('participant') == bmNetPart.get('networkPartner')) {
									bm.get("bmRole").remove(role);
                    				roleArray.push({'bm':bm,'role':role,'refId':[assignment.get("id"),bmNetPart.get('id')],'assign':assignment});
									if(!bm.checkBmNetworkPartnerReused(bmNetPart,role.getParent())){
										obDel.push(bmNetPart);
                						bm.businessCanvasDeletions([bmNetPart.get('networkPartner')]);
									}
								}
							});
						});
					}
					if(bmCustomer) {
						_.each(bmCustomer.get('customerRole').models,function(role){
							_.each(role.get('roleAssignment').models,function(assignment){
								if(assignment.get('participant') == bmCustomer.get('customer')) {
									bm.get("bmRole").remove(role);
                    				roleArray.push({'bm':bm,'role':role,'refId':[assignment.get("id"),bmCustomer.get('id')],'assign':assignment});
									if(!bm.checkBmCustomerReused(bmCustomer,role.getParent())){
										obDel.push(bmCustomer);
                						bm.businessCanvasDeletions([bmCustomer.get('customer')]);
									}
								}
							});
						});
					}
				}
			}
			obDel = _.uniq(obDel);
			var obDelLen = obDel.length;
			while (obDelLen--) {
				obDel[obDelLen].destroy();
			}
			async.eachSeries(roleArray, function(role,roleCallback){
				role.bm.deleteRole(role,roleCallback);
			}, function(err){  
		        callback();
			});
		}
		if(businessObject.$type === "vdml:ValueProposition" || businessObject.$type === "vdml:ValueStream"){
			for(var i=0;i<selectedBms.length;i++){
				if(businessObject.$type === "vdml:ValueProposition"){
				    var vpModel = Backbone.Relational.store.getObjectByName('vdml.ValueProposition').find({id:businessObjectId});
					if(vpModel.get('isValueFormula')){
						var bmValFormula = selectedBms[i].get('bmValueFormula').findWhere({'valueFormula':vpModel});
						if(bmValFormula){ 
						  selectedBms[i].get('bmValueFormula').remove(bmValFormula);
						}
					}else {
						var bmValProp = selectedBms[i].get('bmValueProposition').findWhere({'valueProposition':vpModel});
						if(bmValProp){ 
						  selectedBms[i].get('bmValueProposition').remove(bmValProp);
						}
					}
					

				}else{
					var valueStreamModel = Backbone.Relational.store.getObjectByName('vdml.ValueStream').find({id:businessObjectId});
					var isvalueStreamExist =  _.filter(selectedBms[i].get('bmValueStream').models, function (model) { if (model == valueStreamModel) return true})
					if(isvalueStreamExist){
						    isvalueStreamExist[0] && isvalueStreamExist[0].destroy();
					}
				}
			}
			callback();
		}else if(businessObject.$type === "vdml:Role" || businessObject.$type === "vdml:Party"){
			model = Backbone.Relational.store.getObjectByName('vdml.Party').find({id:businessObject.mid});
			var obDel = [];
			var roleArray = [];
			for(var i=0;i<selectedBms.length;i++){
				var bm = selectedBms[i];
				if(model.get('roleAssignment').at(0) && model.get('roleAssignment').at(0).get('participant') == bm.get('business')){
					_.each(bm.get('participantNetwork').models,function(pn){
						var coll = pn.get('collaboration');
					    if(coll.get('collaborationRole').findWhere({'id':businessObject.mid})){
							var assignment = coll.get('ownedAssignment').findWhere({'assignedRole':model});
							if(assignment){
								roleArray.push({'bm':bm,'role':model,'refId':[assignment.get("id"),bm.get('business').get('id')],'assign':assignment});
							}
							bm.get("bmRole").remove(model);
							var assignmentList = coll.get('ownedAssignment').where({'participant':bm.get('business')});
							if(assignmentList.length == 1){
								pn.removeCollaborationToScenarioContexts(bm);
								obDel.push(pn);
							}
						}
					});
				}else {
					_.each(bm.get('bmCustomer').models,function(bmCustomer){								
						_.each(bmCustomer.get('customerRole').models,function(role){
							if(role == model){
								_.each(role.get('roleAssignment').models,function(assignment){
									if(assignment.get('participant') == bmCustomer.get('customer')) {
										bm.get("bmRole").remove(role);
                    					roleArray.push({'bm':bm,'role':role,'refId':[assignment.get("id"),bmCustomer.get('id')],'assign':assignment});
										if(!bm.checkBmCustomerReused(bmCustomer,role.getParent())){
											obDel.push(bmCustomer);
                							bm.businessCanvasDeletions([bmCustomer.get('customer')]);
										}
									}
								});
							}
						});
					});
					_.each(bm.get('bmNetworkPartner').models,function(bmNetPart){								
						_.each(bmNetPart.get('networkPartnerRole').models,function(role){
							if(role == model){
								_.each(role.get('roleAssignment').models,function(assignment){
									if(assignment.get('participant') == bmNetPart.get('networkPartner')) {
										bm.get("bmRole").remove(role);
                    					roleArray.push({'bm':bm,'role':role,'refId':[assignment.get("id"),bmNetPart.get('id')],'assign':assignment});
										if(!bm.checkBmNetworkPartnerReused(bmNetPart,role.getParent())){
											obDel.push(bmNetPart);
                							bm.businessCanvasDeletions([bmNetPart.get('networkPartner')]);
										}
									}
								});
							}
						});
						
					});
				}
			}
			obDel = _.uniq(obDel);
			var obDelLen = obDel.length;
			while (obDelLen--) {
			   obDel[obDelLen].destroy();
			}
			async.eachSeries(roleArray, function(role,roleCallback){
				role.bm.deleteRole(role,roleCallback);
			}, function(err){  
			    callback();
			});
		}else if(businessObject.$type === "vdml:MarketSegment" ||  businessObject.$type === "vdml:Community"){
			model = Backbone.Relational.store.getObjectByName('vdml.Community').find({id:businessObjectId});
			deleteParticipant(model,callback);
		}else if(businessObject.$type === "vdml:Enterprise" ||  businessObject.$type === "vdml:OrgUnit"){
			model = Backbone.Relational.store.getObjectByName('vdml.OrgUnit').find({id:businessObjectId});
			deleteParticipant(model,callback);
		}else if(businessObject.$type === "vdml:Individual" || businessObject.$type === "vdml:Actor"){
			model = Backbone.Relational.store.getObjectByName('vdml.Actor').find({id:businessObjectId});
			deleteParticipant(model,callback);
		}else if(businessObject.$type === "vdml:BusinessModel"){
			var bmModal = Backbone.Relational.store.getObjectByName('vdml.BusinessModel').find({id:businessObjectId});
			if(bmModal){
				var vdm = bmModal.getNestedParent();
				vdm.removeBusinessModel(bmModal,function(){
					callback();
				});
			}
		}
		else if(businessObject.$type === "vdml:Activity"){
			var actM = Backbone.Relational.store.getObjectByName('vdml.Activity').find({id:businessObjectId});
			var obDel = [];
			for(var i=0;i<selectedBms.length;i++){
				/*var bmModels = selectedBms[i].get('bmActivity');
				for(var j=0;j<bmModels.models.length;j++){
					if(bmModels.at(j).get('activity') == actM){
						obDel.push(bmModels.at(j));
					}
				}*/
				var bmAct = selectedBms[i].get('bmActivity').findWhere({'activity':actM});
				if(bmAct) {
					selectedBms[i].get('bmActivity').remove(bmAct);
				}
			}
			/*obDel = _.uniq(obDel);
			var obDelLen = obDel.length;
			while (obDelLen--) {
				obDel[obDelLen].destroy();
			}*/
			callback();
		}else if(businessObject.$type === "vdml:Competency" || businessObject.$type === "vdml:BusinessItem" || businessObject.$type === "vdml:CapabilityOffer"){
			var compArray = [];var obDel = [];
			var act;
			var currentAlt = DataManager.getDataManager().getAlternativeSync(DataManager.getDataManager().get('viewAlternative'));
			var competence = Backbone.Relational.store.getObjectByName('vdml.Competence').find({id:businessObjectId});
			if(competence.get('type') === 'vdml_BusinessItem') {
				var biFlows = competence.get('flow');
				for (var k = 0; k < biFlows.models.length; k++) {
					 act = biFlows.models[k].get('recipient') ? biFlows.models[k].get('recipient').get('containedPortOwner') : '';
					 if(act){
					 	 break;
					 }
				}
			}else if(selectedBms.length > 0){
				var bmAct = selectedBms[0].get('bmActivity');
				for(var j=0;j<bmAct.models.length;j++){
					var activity = bmAct.models[j].get('activity');
					var capOffers = activity.getActivityCompetences();
					if (capOffers.indexOf(competence) != -1) {
						act = activity;
						break;
					}
				}
			}

			for(var i=0;i<selectedBms.length;i++){
				var scenario = currentAlt.getMainScenario(selectedBms[i].getNestedParent());
				var bmComp = selectedBms[i].get('bmCompetence');
				for(var j=0;j<bmComp.models.length;j++){
					if(bmComp.at(j).get('competence') == competence){
						compArray.push({'bm':selectedBms[i],'bmComp':bmComp.at(j),'scenario':scenario,'activity':act});
						break;
					}
				}	
			}

			async.eachSeries(compArray, function(comp,compCallback){
				var bmCompetenceModal = comp.bmComp;
				var competenceObj = bmCompetenceModal.get('competence');
				comp.bm.deleteBMCompetency(competenceObj,comp.activity,comp.scenario,function(del){
					if(del && competenceObj.get('type') === 'vdml_BusinessItem') {
						obDel.push(comp.bmComp);
					}
					compCallback();
				});
			}, function(err){  
				obDel = _.uniq(obDel);
				var obDelLen = obDel.length;
				while (obDelLen--) {
					obDel[obDelLen].destroy();
				}
		        callback();
			});
		}else if(businessObject.$type === "vdml:Value" || businessObject.$type === "vdml:ValuePropositionComponent" || businessObject.$type === "vdml:ValueAdd"){
		    model = Backbone.Relational.store.getObjectByName('vdml.ValueAdd').find({id:businessObjectId});
			if(!model){
				model = Backbone.Relational.store.getObjectByName('vdml.ValuePropositionComponent').find({id:businessObjectId});
			}
			if(model){
				var propositionModel = model.get('componentOwner');
				if(model && selectedBms.length == 0 && model.getNestedParent() === DataManager.getDataManager().get('currentPlan')){
					model.destroy();
				}else if(propositionModel && !propositionModel.get('isValueFormula')){
					model.destroy();
				}else if(propositionModel && propositionModel.get('isValueFormula')){
					model.destroy();
				}else if(model && model.get('valueAddOwner')){
					model.destroy();
				}
				callback();
			}
		}else if(businessObject.$type == "vdml:BusinessNetwork"){
			model = Backbone.Relational.store.getObjectByName('vdml.BusinessNetwork').find({id:businessObjectId})
			if(model){
			    for(var i=0;i<selectedBms.length;i++){
					var pns = selectedBms[i].get('participantNetwork');
					for(var j=0;j<pns.length;j++){
						if(pns.at(i).get('collaboration') && pns.at(i).get('collaboration') === model){
							pns.at(i).destroy();
						}
					}
				}
				callback();
			}
		}
	}
    
    static deleteSuggestion(model, shapeId) {
        var shapeSuggestions = model.get("suggestion").findWhere({ shapeId: shapeId });
        if(!shapeSuggestions) {
            return;
        }
        var suggestionsLength = shapeSuggestions.collection.models.length;
        while(suggestionsLength--) {
            shapeSuggestions.collection.models[suggestionsLength].destroy();
        }
    }

	static fetchImagesFromXML(xml){
		var deletedCodedContainers = [];
		if(xml){
			var xmlDoc = jQuery.parseXML(xml);
			var $xml = jQuery(xmlDoc);													
			var nodes = $xml.find('[backgroundUrl]');
			nodes.each(function (callback, node) {
				var url = node.getAttribute('backgroundUrl');
				if(url){
					deletedCodedContainers.push(url);
				}
			});
		}
		deletedCodedContainers = _.uniq(deletedCodedContainers);
		return deletedCodedContainers;
	}

	viewMap(model){
		var self = this;
		var items = [{ id: '', name: 'Choose Map...' }]
		var diags = self.get('diagrams'); 
		_.each(diags.models,function(diag){
			if(diag != model){
				items.push({ id: diag.id, name: diag.get('name') });
			}
		})
		var inputOptions = items.map(function(item) {
			return `<option value="${item.id}">${item.name}</option>`;
		});
		bootbox.dialog({
			title: 'View Map',
			message: `
				<form>
					<div class="form-group">
						<select id="bootbox-select" class="form-control">
							${inputOptions.join('')}
						</select>
					</div>
					<div id="js-canvas" style="margin-top: -10px;"></div>
				</form>
			`,
			closeButton: true, // Removes the modal footer
			buttons: {},
			size: 'large'
		});

		$('.bootbox-body select').on('change', function () {
			var selectedValue = $(this).val();
			var selectedText = $(this).find('option:selected').text();
			if (selectedValue) {
				var map = self.get('diagrams').findWhere({'id':selectedValue});
				$('#selection-result').html(selectedText);
				var container = $("#js-canvas");
				container.empty();
				var dialogModeler = new EcoNavigatedViewer({
					container: container,
					type:map.get('type'),
					additionalModules: [
						{
						moveCanvas: [ 'value', '' ],
						zoomScroll: [ 'value', '' ]
						}
					]
				});
				async function myDisplay(dialogModeler) {
					let result = new Promise(function(myResolve, myReject) {
					  myResolve("Works");
					});
					try {
						var xml = map.get('data');
						result = await dialogModeler.importXML(xml);
						var { warnings } = result;
						var height = getViewportSize().h * 0.80 + $('.navbar').height();
						height = height > 700 ? height : 700;
						$("#js-canvas").height(height);
						dialogModeler.get("canvas").resized();
						dialogModeler.get("canvas").zoom("fit-viewport", true);
					} catch (err) {
						console.log(err.message, err.warnings);
					}
				}
				myDisplay(dialogModeler);
			} else {
				$('#selection-result').html('');
				$('#js-canvas').empty();
			}
		});
		$('.bootbox.modal-dialog').css({
			'width': '70%',
			'max-width': '70%'
		});
	}

	static removeImageArtifact(deletedCodedContainers, currentModel, skipPacks){
		var artifactList = [];
		if(deletedCodedContainers && deletedCodedContainers.length > 0){
			deletedCodedContainers = _.uniq(deletedCodedContainers);
			var plan = DataManager.getDataManager().get("currentPlan");
			var beepPacks = plan.getPlanPackageList(["ecomap_EcoMap"],false);
			var capPack = plan.getPlanPackageList(["vdml_ValueDeliveryModel"],true);
			var currentPackage = currentModel.getNestedParent();
			for(var m=0; m < capPack.length; m++){
				if (capPack[m].beepReference.indexOf("Common") > -1) {
					beepPacks.push(capPack[m]);
				}
			}
			var repPack = plan.getPlanPackageList(["report_BeepReport"],true);
			for(var m=0; m < repPack.length; m++){
				beepPacks.push(repPack[m]);
			}
			if(beepPacks.length > 1){
				beepPacks.sort((a, b) => {
					if (a.beepReference === currentPackage.id) {
					  return -1;
					} else if (b.beepReference === currentPackage.id) {
					  return 1;
					} else {
					  return 0;
					}
				});
			}
			for(var i=0; i < deletedCodedContainers.length; i++){
				var foundImage = false;
				var selfPackIteration = false;
				for(var j=0; j<beepPacks.length; j++){
					var beepId = beepPacks[j].beepReference;
					var type = beepPacks[j].beepType.replace('_', '.');
					if(skipPacks && skipPacks.indexOf(beepId) != -1){
						continue;
					}
					var ecoPackage = window.utils.getElementModel(beepId,[type]);
					if(ecoPackage){
						/*if(ecoPackage.get('content') && ecoPackage.get('content').search(deletedCodedContainers[i]) != -1){
							foundImage = true;
						}*/
						if(ecoPackage == currentPackage){
							var selfPackImageFound = false;
							selfPackIteration = true;
							var diagrams = ecoPackage.get('diagrams');
							if(diagrams){
								for (var k = 0; k < diagrams.length; k++) {
									if(skipPacks && skipPacks.indexOf(diagrams.at(k).id) != -1){
										continue;
									}
									var newXML = diagrams.at(k).get('data');
									selfPackImageFound = window.utils.searchXmlData(newXML, 'backgroundUrl', deletedCodedContainers[i]);
									if(selfPackImageFound){
										foundImage = true;
										break;
									}
								}
							}
							if(!selfPackImageFound){
								var codeContainer = window.utils.getElementModel(deletedCodedContainers[i],['beeppackage.CodeContainer']);
								if(codeContainer){
									ecoPackage.get('dependentPackage').remove(codeContainer);	
								}
							}
						} else if(ecoPackage.get('dependentPackage').findWhere({'id':deletedCodedContainers[i]})){
							foundImage = true;
						}
					}
					if(foundImage && selfPackIteration){
						break;
					}
				}	
				if(!foundImage){
					artifactList.push(deletedCodedContainers[i]);
				}				
			}
			if(artifactList.length > 0){
				var serverChangeSet = DataManager.getDataManager().get(DataManager.getDataManager().CURRENT_CHANGESET);
				for(var i=0;i<artifactList.length; i++){
					var codeContainer = window.utils.getElementModel(artifactList[i],['beeppackage.CodeContainer']);
					if(codeContainer){
						DataManager.getDataManager().addpackageForDelete(serverChangeSet,codeContainer,plan);
						codeContainer.destroy({silent: true});//for plan delete suppress will be true
					}
				}
			}
		}
		return artifactList;
	}

	removeMid(xml,removeCap){
		var self = this;
		if(xml){
			var xmlDoc = jQuery.parseXML(xml);
			var $xml = jQuery(xmlDoc);													
			var nodes = $xml.find('[mpid]');
			nodes.each(function (callback, node) {
				node.removeAttribute('mid');
				node.removeAttribute('mpid');
				if(removeCap){
					node.removeAttribute('mcpid');
				}
			});
			xml = (new XMLSerializer()).serializeToString(xmlDoc);
		}
		return xml;
	}

	fixXMLOnRevision(xml,oldId,newId){
		var self = this;
		if(xml){
			var xmlDoc = jQuery.parseXML(xml);
			var $xml = jQuery(xmlDoc);
			var nodes = $xml.find('[id="' + oldId + '"]');
			var nodes1 = $xml.find('[vdmlElement="' + oldId + '"]')
			if(nodes.length == 0){
				var cusnodes = $xml.find('[vdmlElement^="@"]');
				if(cusnodes.length > 0){
					oldId = cusnodes.attr('vdmlElement');
					nodes = $xml.find('[id="' + oldId + '"]');
				}else {
					oldId = window.utils.htmlEscape(oldId);
					nodes = $xml.find('[id="' + oldId + '"]');
					if(nodes.length == 0){
						oldId = $xml.children().children().get(0).getAttribute('id');
						nodes = $xml.find('[id="' + oldId + '"]');
						if(nodes.length > 0 && nodes1.length == 0){
							nodes1 = $xml.find('[vdmlElement="' + oldId + '"]');
						}
					}
				}
			}
			nodes.each(function (callback, node) {
				node.setAttribute('id', newId);
			})
			nodes1.each(function (callback, node) {
				node.setAttribute('vdmlElement', newId);
			})
			xml = (new XMLSerializer()).serializeToString(xmlDoc);
		}
		return xml;
	}
	
	fixDiagramOnRevision(diagram,viewAltId,oldAltId){
		var self = this;
		var diagramType = diagram.get('type').split("_");
		var diagramTypeSuffix = "e";
		if(diagramType[1]){
			diagramTypeSuffix = diagramType[1].charAt(0).toLowerCase();
		}
		var suffixId = window.utils.getSuffix(diagram.id);
		var oldId = diagramTypeSuffix + window.utils.htmlEscape(oldAltId + suffixId);
		var newId = diagramTypeSuffix + window.utils.htmlEscape(diagram.id);
		var newXML = diagram.get('data');
		newXML = self.fixXMLOnRevision(newXML,oldId,newId);
		
		var alternativeModel = DataManager.getDataManager().getAlternativeSync(viewAltId);
		function changeMappedId(models, jqueryXml) {
			if (!models){
				return jqueryXml;
			}
			var modelsLen = models.length;
			while (modelsLen--) {
				var oldModal = models.at(modelsLen);
				var oldId = oldAltId + window.utils.getSuffix(oldModal.id);
				//var altId = DataManager.getDataManager().getRepositoryId(oldId);
				//if (altId !== viewAltId) {
					var newModel = alternativeModel.getModelLinkedSync(oldModal);
					if (newModel) {
						//models.remove(oldModal, { creatingRevision: true, revisionCollectionNewId: newModel.id });
						if(oldModal != newModel){
							models.remove(oldModal);
							models.add(newModel);
						}
						var parentId = newModel.getNestedParent().id;
						var nodes = jqueryXml.find('[mid="' + oldId + '"]');
						nodes.each(function (callback, node) {
							node.setAttribute('mid', newModel.id);
							node.setAttribute('mpid', parentId);
						})
					} else {
						models.remove(oldModal);
						var nodes = jqueryXml.find('[mid="' + oldId + '"]');
						nodes.each(function (callback, node) {
							node.removeAttribute('mid');
							node.removeAttribute('mpid');
						});
					}
				//}
			}
			return jqueryXml;
		}
		var xmlDoc = jQuery.parseXML(newXML);
		var jqueryXml = jQuery(xmlDoc);		
		jqueryXml = changeMappedId(diagram.get('participant'),jqueryXml);
		jqueryXml = changeMappedId(diagram.get('businessModel'),jqueryXml);
		jqueryXml = changeMappedId(diagram.get('valueProposition'),jqueryXml);
		jqueryXml = changeMappedId(diagram.get('activity'),jqueryXml);
        jqueryXml = changeMappedId(diagram.get('value'),jqueryXml);
        jqueryXml = changeMappedId(diagram.get('valueStream'),jqueryXml);
        jqueryXml = changeMappedId(diagram.get('competence'),jqueryXml);
		newXML = (new XMLSerializer()).serializeToString(xmlDoc);
		diagram.set('data',newXML);
	};

	refreshLabels(modeler,fontsize, callback) {
		modeler.get('textRenderer').getDefaultStyle().fontSize = fontsize;//+'px';
		modeler.get('textRenderer').getExternalStyle().fontSize = fontsize;//+'px';
		
		setTimeout(function () {
			var obj = modeler.get('elementRegistry')._elements;
			for (var key in obj) {
				if (obj.hasOwnProperty(key)) {
					var val = obj[key];
					var shape = val.element;
					var name = shape.businessObject?shape.businessObject.name : null;
					if(name){
						modeler.get('modeling').updateLabel(shape, name);
					}
				}
			}
			callback();
		},100)
	}

	checkRevision(model, callback) {
		var self = this;
		var viewAltId = DataManager.getDataManager().get('viewAlternative');
		var alternativeModel = DataManager.getDataManager().getAlternativeSync(viewAltId);
		var phaseDesignPart = alternativeModel.get('phaseDesignPart');
		var revision = false;
		function checkDiagram(diagram) {
			function checkRevisionForModel(models) {
				if (revision || !models) {
					return;
				}
				for (var i = 0; i < models.length; i++) {
					var parentId = models.at(i).getNestedParent().id;
                    if (!phaseDesignPart.findWhere({ 'beepReference': parentId }) && DataManager.getDataManager().getRepositoryId(parentId) != window.plansKey) {
						revision = true;
						break;
					}
				}
			}
			checkRevisionForModel(diagram.get('participant'));
			checkRevisionForModel(diagram.get('businessModel'));
			checkRevisionForModel(diagram.get('valueProposition'));
			checkRevisionForModel(diagram.get('activity'));
            checkRevisionForModel(diagram.get('value'));
            checkRevisionForModel(diagram.get('valueStream'));
			checkRevisionForModel(diagram.get('competence'));
		}

		var diagrams = self.get('diagrams');
		for (var i = 0; i < diagrams.length; i++) {
			checkDiagram(diagrams.at(i));
			if (revision) {
				break;
			}
		}
		callback && callback(revision);
	}

    static setCustomerDiagramId(model, bm, diagBO, customer, role, callback) {
        if (diagBO) {
            if (diagBO.$type === "vdml:Role") {
                diagBO.set('vdml:mid', role.id);
                diagBO.set('vdml:mpid', bm.getNestedParent().id);
                model.get('participant').add(role);
                if (!diagBO.get('name') || diagBO.get('name') === '') {
                    diagBO.set('name', role.get('name'));
                }
                if (!diagBO.get('description') || diagBO.get('description') === '') {
                    diagBO.set('description', role.get('description'));
                }
            } else {
                diagBO.set('vdml:mid', customer.get('customer').id);
                diagBO.set('vdml:mpid', bm.getNestedParent().id);
                model.get('participant').add(customer.get('customer'));
                if (!diagBO.get('name') || diagBO.get('name') === '') {
                    diagBO.set('name', customer.get('customer').get('name'));
                }
                if (!diagBO.get('description') || diagBO.get('description') === '') {
                    diagBO.set('description', customer.get('customer').get('description'));
                }
            }
        }
        if(callback){
            callback();
        }
    }

    static setPartnerDiagramId(model, bm, diagBO, partner, role, callback) {
        if (diagBO) {
            if (diagBO.$type === "vdml:Role") {
                diagBO.set('vdml:mid', role.id);
                diagBO.set('vdml:mpid', bm.getNestedParent().id);
                model.get('participant').add(role);
                if (!diagBO.get('name') || diagBO.get('name') === '') {
                    diagBO.set('name', role.get('name'));
                }
                if (!diagBO.get('description') || diagBO.get('description') === '') {
                    diagBO.set('description', role.get('description'));
                }
            } else {
                diagBO.set('vdml:mid', partner.get('networkPartner').id);
                diagBO.set('vdml:mpid', bm.getNestedParent().id);
                model.get('participant').add(partner.get('networkPartner'));
                if (!diagBO.get('name') || diagBO.get('name') === '') {
                    diagBO.set('name', partner.get('networkPartner').get('name'));
                }
                if (!diagBO.get('description') || diagBO.get('description') === '') {
                    diagBO.set('description', partner.get('networkPartner').get('description'));
                }
            }
        }
        if(callback){
            callback();
        }
    }

    static setBusinessDiagramId(model, bm, diagBO, participant, role, callback) {
        if (diagBO) {
            if (diagBO.$type === "vdml:Role") {
                diagBO.set('vdml:mid', role.id);
                diagBO.set('vdml:mpid', bm.getNestedParent().id);
                model.get('participant').add(role);
                if (!diagBO.get('name') || diagBO.get('name') === '') {
                    diagBO.set('name', role.get('name'));
                }
                if (!diagBO.get('description') || diagBO.get('description') === '') {
                    diagBO.set('description', role.get('description'));
                }
            } else {
                diagBO.set('vdml:mid', participant.id);
                diagBO.set('vdml:mpid', bm.getNestedParent().id);
                model.get('participant').add(participant);
                if (!diagBO.get('name') || diagBO.get('name') === '') {
                    diagBO.set('name', participant.get('name'));
                }
                if (!diagBO.get('description') || diagBO.get('description') === '') {
                    diagBO.set('description', participant.get('description'));
                }
            }
        }
        if (callback) {
            callback();
        }
    }
}
path.EcoMapMixin = EcoMapMixin;
	//return EcoMapMixin;
//});